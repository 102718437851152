import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PrincipalService } from '@app/principal/service/principal.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (excludedURI.some((uri) => request.url.includes(uri))) {
      const accessToken = PrincipalService.accessToken;
      const isLoggedIn = !!accessToken;
      if (isLoggedIn) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      }
    }
    return next.handle(request);
  }
}

const excludedURI = ['/api/'];
