<ng-container *ngIf="signInOperation$ | async"></ng-container>

<form [formGroup]="signInFormGroup" (ngSubmit)="login()">

  <div class="title">
    Авторизация
  </div>

  <div class="form-item">
    <label>Телефон</label>
    <div class="input-container login">
      <input
        readonly
        type="tel"
        prefix="+7"
        [showMaskTyped]="true"
        placeholder="+7 (___) ___ __ __"
        mask=" (000) 000 00 00"
        [formControlName]="signInFormKeys.Login">
    </div>
  </div>

  <div class="form-item">
    <label>Пароль</label>
    <div class="input-container password">
      <input
        data-testid="password-input"
        #password
        type="password"
        placeholder="Пароль"
        [formControlName]="signInFormKeys.Password"
        autocomplete="new-password"
        required>
      <button
        type="button"
        class="p-inputgroup-addon"
        (click)="password.type = password.type === 'password' ? 'text' : 'password'">
        <i class="pi" [ngClass]="password.type === 'password' ? 'pi-eye' : 'pi-eye-slash'"></i>
      </button>
    </div>
  </div>

  <ng-template [ngIf]="errorCode">
    <p class="message error">{{errorCodeText}}</p>
  </ng-template>

  <div class="forgot-password-button">
    <a
      data-testid="forgot-password-link"
      class="link"
      (click)="goToForgotPasswordState()">
      Забыли пароль?
    </a>
  </div>

  <app-form-actions>

    <button
      type="submit"
      pButton
      class="primary"
      [loading]="signInOperationInFly$ | async"
      [disabled]="signInFormGroup.invalid || signInFormGroup.pending"
      [label]="'Войти'">
    </button>

    <button
      data-testid="go-back"
      type="button"
      class="go-back"
      pButton
      label="Назад"
      (click)="goBack()">
    </button>

  </app-form-actions>

</form>
