export enum LanguageList {
  RU = 'ru',
  EN = 'en',
  KZ = 'kz',
}

export enum LanguageKeyList {
  RU = 'nameRu',
  EN = 'nameEn',
  KZ = 'nameKz',
}
