import { InjectionToken, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '@environments/environment';
import { VideoPlayerComponent } from './video-player.component';

export const STREAMING_SERVICE_URI = new InjectionToken<string>('StreamingServiceURI');

@NgModule({
  declarations: [VideoPlayerComponent],
  imports: [CommonModule],
  exports: [VideoPlayerComponent],
  providers: [
    {
      provide: STREAMING_SERVICE_URI,
      useValue: environment.apiVideoUrl,
    },
  ],
})
export class VideoPlayerModule {}
