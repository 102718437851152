<ng-container *ngIf="validateSMSCodeOperation$ | async"></ng-container>
<ng-container *ngIf="codeInputFormControl$ | async"></ng-container>

<form [formGroup]="validateSMSCodeFormGroup" (ngSubmit)="validateSMSCode()">
  <div class="title">
    Вам отправлен код. Введите его.
  </div>

  <div class="form-item">
    <label>Телефон</label>
    <div class="input-container login full">
      <input
        data-testid="phone-number-input"
        readonly
        type="text"
        prefix="+7"
        mask=" (000) 000 00 00"
        [formControlName]="validateSMSCodeFormControlKeys.PhoneNumber">
    </div>
  </div>

  <div class="form-item">
    <label>Код</label>
    <div class="input-container full">
      <input
        data-testid="code-input"
        #code
        mask="0000"
        type="text"
        placeholder="0000"
        [formControlName]="validateSMSCodeFormControlKeys.Code"
        autocomplete="new-password"
        maxlength="4"
        required>
    </div>
  </div>

  <div class="message">
    <app-resend-sms-code
      [phoneNumber]="phoneNumber || '7776750007'">
    </app-resend-sms-code>

    <ng-container *ngIf="errorCode">
      <span class="error" data-testid="error-code-text">{{errorCodeText}}</span>
    </ng-container>
  </div>

  <app-form-actions>
    <button
      data-testid="send-code-button"
      type="submit"
      class="primary"
      pButton
      [loading]="validateSMSCodeOperationInFly$ | async"
      [disabled]="validateSMSCodeFormGroup.invalid"
      [label]="'Войти'">
    </button>

    <button
      data-testid="go-back-button"
      type="button"
      class="go-back"
      pButton
      label="Отредактировать номер"
      (click)="goBack()">
    </button>
  </app-form-actions>
</form>
