import { Injectable } from '@angular/core';
import { DeviceService } from '@helpers/device.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import * as userModels from '@modules/user/models';

import { CURRENT_USER_KEY, JWT_TOKEN_KEY } from 'const';

import * as models from '../models';

@Injectable({
  providedIn: 'root',
})
export class PrincipalService {
  #disconnectDevice$: Subscription;

  constructor(private deviceService: DeviceService) {}

  public static get refreshToken(): string {
    return PrincipalService.getToken()?.refresh_token;
  }

  public static get accessToken(): string {
    return PrincipalService.getToken()?.access_token;
  }

  #currentUserSubject$ = new BehaviorSubject<userModels.UserProfile>(null);

  currentUserSubject$: Observable<userModels.UserProfile> = this.#currentUserSubject$.asObservable();

  static getToken(): models.RawToken {
    return JSON.parse(localStorage.getItem(JWT_TOKEN_KEY));
  }

  static setToken(token: models.RawToken): void {
    localStorage.setItem(JWT_TOKEN_KEY, JSON.stringify(token));
  }

  public currentUser(): Observable<userModels.UserProfile> {
    return this.#currentUserSubject$.asObservable();
  }

  public setCurrentUser(currentUser: userModels.UserProfile): void {
    if (currentUser) {
      localStorage.setItem(CURRENT_USER_KEY, JSON.stringify(currentUser));
      this.#currentUserSubject$.next(currentUser);
    }
  }

  public logout(): void {
    this.#currentUserSubject$.next(null);
    this.#disconnectDevice$ = this.deviceService.disconnectDeviceFromUserAccount(PrincipalService.accessToken).subscribe();
    localStorage.removeItem(JWT_TOKEN_KEY);
    localStorage.removeItem(CURRENT_USER_KEY);
  }

  public static get accessTokenExists(): boolean {
    return !!PrincipalService.accessToken;
  }

  public static get currentUser(): userModels.UserProfile {
    return JSON.parse(localStorage.getItem(CURRENT_USER_KEY));
  }
}
