import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { SettingsService } from '@app/settings.service';
import { UtilService } from '@helpers/util.service';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent implements OnInit {
  @Input() public size: 'full' | 'icon';
  @Input() photoUuid: string;
  @Input() agentName: string;
  @Input() public label: string;
  @Input() public sizeAvatar: 'default' | 'small' = 'default';

  @Input() avatarSize = 70;
  public isMobile: boolean;

  constructor(
    public util: UtilService,
    private _settingsService: SettingsService,
  ) {}

  ngOnInit() {
    this._settingsService.isMobile().subscribe((isMobile: boolean) => {
      this.isMobile = isMobile;
    });
  }
  public onError(event: Event): void {
    (event.target as HTMLImageElement).src = 'assets/images/no_avatar.png';
  }

  public get url() {
    return `${this.util.getFilePath()}${this.photoUuid}/preview`;
  }
}
