<ng-container *ngIf="setupPasswordOperation$ | async"></ng-container>

<form [formGroup]="passwordSetupForm" (ngSubmit)="setPassword()">

  <div class="title">

    Придумайте пароль

  </div>

  <div class="form-item">
    <label>Пароль</label>
    <div class="input-container">
      <input
        data-testid="password-input"
        #password
        type="password"
        placeholder="Пароль"
        [formControlName]="passwordSetupFormControlKeys.Password"
        autocomplete="new-password"
        required>
      <button
        type="button"
        class="p-inputgroup-addon"
        (click)="password.type = password.type === 'password' ? 'text' : 'password'">
        <i class="pi" [ngClass]="password.type === 'password' ? 'pi-eye' : 'pi-eye-slash'"></i>
      </button>
    </div>
  </div>

  <div class="form-item">
    <label>Повторите пароль</label>
    <div class="input-container">
      <input
        data-testid="password-input"
        #confirmPassword
        type="password"
        placeholder="Пароль"
        [formControlName]="passwordSetupFormControlKeys.ConfirmPassword"
        autocomplete="new-password"
        required>
      <button
        type="button"
        class="p-inputgroup-addon"
        (click)="confirmPassword.type = confirmPassword.type === 'password' ? 'text' : 'password'">
        <i class="pi" [ngClass]="confirmPassword.type === 'password' ? 'pi-eye' : 'pi-eye-slash'"></i>
      </button>
    </div>
    <ng-container *ngIf="passwordSetupForm?.errors?.passwordNotSameError &&
      (passwordSetupForm.get(passwordSetupFormControlKeys.ConfirmPassword).touched ||
      passwordSetupForm.get(passwordSetupFormControlKeys.ConfirmPassword).dirty)">
      <div class="message error">
        Пароли не совпадают
      </div>
    </ng-container>
  </div>

  <div class="message">

    <ng-container *ngIf="errorCode">
      {{errorCodeText}}
    </ng-container>

  </div>

  <app-form-actions>

    <button
      data-testid="password-setup-button"
      type="submit"
      class="primary"
      pButton
      [loading]="setupPasswordOperationInFly$ | async"
      [disabled]="passwordSetupForm.invalid"
      [label]="'Авторизоваться'">
    </button>

  </app-form-actions>

</form>
