import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Params } from '@angular/router';

@Component({
  selector: 'app-residential-complex-details-link',
  templateUrl: './residential-complex-details-link.component.html',
  styleUrls: ['./residential-complex-details-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResidentialComplexDetailsLinkComponent {
  @Input()
  public readonly id: number | string = null;

  @Input()
  public readonly queryParams: Params = null;
}
