import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import * as authModels from './auth.model';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@environments/environment';

import * as models from './request-code-api.model';

@Injectable({
  providedIn: 'root',
})
export class RequestCodeApiService {
  constructor(private http: HttpClient) {}

  public getVerificationCode(queryParams: models.RequestCodeApiParams['query']): Observable<authModels.APIResponse> {
    return this.http.get(`${environment.apiUserManagerUrl}/open-api/users/requestCode?login=${queryParams.login}`, { observe: 'response' }).pipe(
      map(({ ok }) => ({ ok })),
      catchError(({ ok, error }) => of({ ok, errorCode: error })),
    );
  }
}
