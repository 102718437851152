import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import videojs from 'video.js/dist/video.min';
import { STREAMING_SERVICE_URI } from './video-player.module';
import { SettingsService } from '@app/settings.service';

@Component({
  selector: 'app-video-player',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <video
      [poster]="poster"
      id="video-{{ prefix }}-{{ id }}"
      [controls]="true"
      [autoplay]="playVideo"
      allow="autoplay"
      [muted]="true"
      [ngStyle]="{ 'object-fit': horizontal === true ? 'contain' : 'cover' }"
      playsinline
      loop
    ></video>
    <div class="pause-btn">
      <img src="assets/images/play-button.svg" alt="" />
    </div>
    <div class="sound" [class.no-muted]="!isMuted" (click)="$event.stopPropagation(); changeSound()">
      <img [src]="'assets/images/' + (isMuted ? 'sound-off' : 'sound-on') + '.svg'" />
    </div>
  `,
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() public readonly id: number;
  @Input() public playVideo: boolean;
  @Input() public readonly source: string;
  @Input() public readonly prefix: string;

  public poster = 'assets/images/video-loading.png';
  public player: videojs.Player;
  public horizontal: boolean = null;
  private videoEl: HTMLMediaElement;
  public isMobile: boolean;
  public desktopCounter = 0;
  public isMuted = true;
  private destroy$: Subject<void> = new Subject<void>();
  private options = { autoplay: true, controls: true, muted: true };

  readonly #videoServiceURI: string;

  constructor(
    @Inject(STREAMING_SERVICE_URI) private streamingServiceURI: string,
    private _settingsService: SettingsService,
  ) {
    this.#videoServiceURI = this.streamingServiceURI;
  }

  public ngAfterViewInit(): void {
    this.videoEl = this.#getVideoElement();
    this._settingsService.isMobile().subscribe((data) => (this.isMobile = data));
    this.#togglePlayer();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.playVideo.firstChange && changes.playVideo.currentValue) {
      this.playVideo = changes.playVideo.currentValue;
      this.#togglePlayer();
    } else if (changes.playVideo.currentValue !== changes.playVideo.previousValue) {
      this.playVideo = changes.playVideo.currentValue;
      this.#togglePlayer();
    }
  }

  public ngOnDestroy(): void {
    if (this.player) this.player.dispose();
    this.destroy$.next();
    this.destroy$.complete();
  }

  public changeSound(): void {
    this.player.muted() ? this.player.muted(false) : this.player.muted(true);
    this.isMuted = this.player.muted();
  }

  #togglePlayer(): void {
    if (this.videoEl) {
      this.#initPlayer(this.videoEl);
    }
  }

  #initPlayer(videoEl: HTMLMediaElement): void {
    if (!this.source) {
      console.warn('Source not provided for', this.prefix, this.id);
      return;
    }

    const manifestUri = this.#videoServiceURI + this.source;

    this.player = videojs(videoEl, this.options);
    this.player.src({
      src: manifestUri,
      type: 'application/x-mpegURL',
    });

    this.player.play();

    document.querySelector('.pause-btn').classList.add('active');

    fromEvent(document.querySelector('.vjs-v7'), 'click')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.handleVideo();
      });

    fromEvent(document.querySelector('.pause-btn'), 'click')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.handleVideo();
      });
  }

  private handleVideo(): void {
    if (this.isMobile) {
      this.player.paused() ? this.startVideo() : this.stopVideo();
      return;
    }

    this.desktopCounter = this.desktopCounter + 1;
    this.desktopCounter % 2 === 0 ? this.startVideo() : this.stopVideo();
  }

  private startVideo(): void {
    this.player.play();
    document.querySelector('.pause-btn').classList.add('active');
  }

  private stopVideo(): void {
    this.player.pause();
    document.querySelector('.pause-btn').classList.remove('active');
  }

  #getVideoElement(): HTMLMediaElement {
    const element = document.getElementById(`video-${this.prefix}-${this.id}`) as HTMLMediaElement;
    if (element) return element;
    else {
      console.warn('Video element no found for', this.prefix, this.id);
      return null;
    }
  }
}
