<ng-container *ngIf="phoneNumberInput$ | async"></ng-container>
<ng-container *ngIf="getIsPhoneRegisteredStatus$ | async"></ng-container>

<form class="auth" [formGroup]="initialForm" (ngSubmit)="getPhoneNumberStatus()">

  <div class="title">

    Войти или зарегистрироваться

  </div>

  <div class="form-item">
    <label>Телефон</label>
    <div class="input-container login full">
      <input
        data-testid="phone-input"
        #login
        type="text"
        prefix="+7"
        placeholder="Телефон"
        mask=" (000) 000 00 00"
        [formControlName]="initialFormKeys.Login"
        autocomplete="username"
        required>
    </div>
  </div>

  <app-form-actions>

    <button
      data-testid="next-button"
      type="submit"
      pButton
      class="primary"
      [loading]="getIsPhoneRegisteredLoading$ | async"
      [disabled]="initialForm.invalid"
      [label]="'Продолжить'">
    </button>

  </app-form-actions>

</form>
