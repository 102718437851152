import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(
    private readonly _titleService: Title,
    private readonly _metaService: Meta,
  ) {}

  public setTitle(title: string): void {
    this._titleService.setTitle(title);
    this._metaService.updateTag({
      property: 'og:title',
      content: title,
    });
  }

  public setDescription(description: string): void {
    this._metaService.updateTag({
      name: 'description',
      content: description,
    });
    this._metaService.updateTag({
      property: 'og:description',
      content: description,
    });
  }

  public setUrl(url: string): void {
    this._metaService.updateTag({
      property: 'og:url',
      content: url,
    });
  }

  public setRobots(robots: string): void {
    this._metaService.updateTag({
      name: 'robots',
      content: robots,
    });
  }
}
