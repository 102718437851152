<ng-container [ngSwitch]="signInState">

  <ng-container *ngSwitchCase="signInStateList.SignIn">

    <app-sign-in-state
      [phoneNumber]="phoneNumber"
      (forgotPasswordState)="goToForgotPasswordState()"
      (goNext)="close()"
      (goBackFromSignInState)="goBack()">
    </app-sign-in-state>

  </ng-container>

  <ng-container *ngSwitchCase="signInStateList.ForgotPassword">
    <app-forgot-password-state
      [accountRestore]="accountRestore"
      [phoneNumber]="phoneNumber"
      (closeForgotPass)="close()"
      (goBackFromForgotPass)="goToSignInState()">
    </app-forgot-password-state>
  </ng-container>

</ng-container>
