import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyFormat',
})
export class CurrencyFormat implements PipeTransform {
  transform(value: number, currencySign = '€ ', decimalLength = 0, chunkDelimiter = '.', decimalDelimiter = ',', chunkLength = 6): string {
    // value /= 100;

    const result = '\\d(?=(\\d{' + chunkLength + '})+' + (decimalLength > 0 ? '\\D' : '$') + ')';
    // tslint:disable-next-line:no-bitwise
    const num = value.toFixed(Math.max(0, ~~decimalLength));

    return (decimalDelimiter ? num.replace('.', decimalDelimiter) : num).replace(new RegExp(result, 'g'), '$&' + chunkDelimiter) + ' ' + currencySign;
  }
}
