import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RequestCodeService } from '@modules/sign-in/components/sign-up/components/send-code/request-code.service';

enum SignUpFormState {
  SendCode = 'sendCode',
  RegisterUser = 'registerUser',
}

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  @Input()
  public resumeRegister: boolean;

  @Input()
  public phoneNumber: string;

  public token: string;

  @Output()
  public goBackFromSignUp: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public closeSignUp: EventEmitter<void> = new EventEmitter<void>();

  public readonly signUpStateList = SignUpFormState;
  public signUpState: SignUpFormState;

  constructor(private requestCodeService: RequestCodeService) {}

  ngOnInit(): void {
    if (this.resumeRegister === true) {
      this.signUpState = SignUpFormState.SendCode;
    } else {
      this.requestCodeService.requestCode(this.phoneNumber);
      this.signUpState = SignUpFormState.SendCode;
    }
  }

  public goToRegisterUserState(token: string): void {
    this.token = token;
    this.signUpState = SignUpFormState.RegisterUser;
  }

  public goToSendCodeState(): void {
    this.signUpState = this.signUpStateList.SendCode;
  }

  public onSignUp(): void {
    this.closeSignUp.emit();
  }

  public goBack(): void {
    this.goBackFromSignUp.emit();
  }
}
