import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { exhaustMap, tap } from 'rxjs/operators';
import { SendSmsCodeApiService } from './send-sms-code-api.service';

import * as models from './send-code.model';

@Injectable({
  providedIn: 'root',
})
export class SmsCodeService {
  #sendCodeOperationInFlySubject = new Subject<boolean>();
  public sendCodeOperationInFly$ = this.#sendCodeOperationInFlySubject.asObservable();

  #sendCodeOperationSubject: Subject<models.SendCodePayload> = new Subject<models.SendCodePayload>();
  public sendCodeOperation$ = this.#sendCodeOperationSubject.asObservable().pipe(
    tap(() => this.#sendCodeOperationInFlySubject.next(true)),
    exhaustMap((code) => this.#sendCode(code)),
  );

  constructor(private sendSMSCodeApiService: SendSmsCodeApiService) {}

  public sendCode(payload: models.SendCodePayload): void {
    this.#sendCodeOperationSubject.next(payload);
  }

  #sendCode({ phoneNumber: login, code: password }: models.SendCodePayload): Observable<{ ok: boolean; token?: string; errorCode?: string }> {
    return this.sendSMSCodeApiService.validateSMSCode({ login, password }).pipe(tap(() => this.#sendCodeOperationInFlySubject.next(false)));
  }
}
