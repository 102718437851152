import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { UtilService } from '@helpers/util.service';

@Component({
  selector: 'app-network-image',
  templateUrl: './network-image.component.html',
  styleUrls: ['./network-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NetworkImageComponent implements OnInit {
  @Input()
  public readonly source: string;

  @Input()
  public readonly preview: boolean = false;

  public path: string;

  constructor(private utilService: UtilService) {}

  ngOnInit(): void {
    const path = `${this.utilService.getFilePath()}${this.source}`;
    this.path = this.preview ? `${path}/preview` : path;
  }

  public onError(event: Event): void {
    (event.target as HTMLImageElement).src = 'assets/images/no_image.jpeg';
  }
}
