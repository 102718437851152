import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ScrollToTopService } from '@app/components/scroll-to-top-button/services/scroll-to-top.service';

@Component({
  selector: 'app-scroll-to-top-button',
  templateUrl: './scroll-to-top-button.component.html',
  styleUrls: ['./scroll-to-top-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollToTopButtonComponent {
  @Input() isMobile = false;
  constructor(private scrolledService: ScrollToTopService) {}

  scrollToTop(): void {
    this.scrolledService.elementToScroll.getValue().scrollTo(0, 0);
  }
}
