<ng-container *ngIf="resendCodeRequest$ | async"></ng-container>
<ng-container *ngIf="requestCodeOperation$ | async"></ng-container>

<ng-container *ngIf="codeResendInFly === false">
  <button
    [disabled]="!phoneNumber"
    data-testid="resend-code-button"
    type="button"
    pButton
    class="p-button-text link"
    (click)="requestCode()"
    [label]="'Не пришел код?'">
  </button>
</ng-container>

<ng-container *ngIf="resendCodeAfter$ | async as timer">
  {{ timer > 0 ? 'Повторно запросить код можно будет через ' + timer : '' }}
</ng-container>
