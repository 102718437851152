import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable, of } from 'rxjs';
import { exhaustMap, filter, map, switchMap } from 'rxjs/operators';

@Component({
  template: '<ng-container *ngIf="params$ | async"></ng-container>',
  styles: [
    `
      .fullscreen-modal {
        width: 100%;
        height: 100%;
      }
    `,
  ],
})
export class ModalComponent implements OnInit {
  params$: Observable<any>;

  constructor(
    private route: ActivatedRoute,
    private _dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    this.params$ = this.route.queryParamMap.pipe(
      filter((params) => params.has('id')),
      map((params) => parseInt(params.get('id'), 10)),
      switchMap((id) =>
        this.route.data.pipe(
          exhaustMap(({ component }) =>
            of(
              this._dialogService.open(component, {
                showHeader: false,
                data: {
                  id,
                },
              }),
            ),
          ),
        ),
      ),
    );
  }
}
