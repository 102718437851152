import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as principalModels from '@app/principal/models';
import { PrincipalService } from '@app/principal/service/principal.service';
import { environment } from '@environments/environment';
import { DeviceService } from '@helpers/device.service';
import * as models from '@modules/auth/models';
import * as userModels from '@modules/user/models';
import * as userServices from '@modules/user/services';
import { CLIENT_ID, USER_MANAGER_URI } from 'const';
import { MessageService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, filter, map, tap } from 'rxjs/operators';

type Credentials = { login: string; password: string };

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    @Inject(USER_MANAGER_URI) private userManagerURI: string,
    private http: HttpClient,
    private principalService: PrincipalService,
    private userService: userServices.UserService,
    private deviceService: DeviceService,
    private messageService: MessageService,
  ) {}

  public authenticate(login: models.AuthParams): Observable<models.AuthResp> {
    return this.http.post<models.AuthResp>(`${this.userManagerURI}/open-api/users/clientAuthorizationFromClient?login=${login}`, null);
  }

  public login(credentials: Credentials): Observable<userModels.UserProfile> {
    return this.#loginIDP(credentials).pipe(
      exhaustMap((token) =>
        this.userService.getUserProfile(token?.access_token).pipe(
          filter((user) => !!user),
          tap((user) => this.principalService.setCurrentUser(user)),
        ),
      ),
    );
  }

  #loginIDP({ login, password }: Credentials): Observable<principalModels.RawToken> {
    const body = new HttpParams().set('username', login).set('password', password).set('grant_type', 'password').set('client_id', CLIENT_ID);
    return this.http
      .post<principalModels.RawToken>(`${environment.authUrl}`, body.toString(), {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
      })
      .pipe(
        exhaustMap((token) => {
          if (token?.access_token) {
            PrincipalService.setToken(token);
            return this.deviceService.connectDeviceToUserAccount().pipe(
              map(() => {
                return token;
              }),
            );
          } else {
            return of(token);
          }
        }),
      );
  }

  public refreshToken(): Observable<principalModels.RawToken> {
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
    };

    const body = new HttpParams().set('refresh_token', PrincipalService.refreshToken).set('grant_type', 'refresh_token').set('client_id', CLIENT_ID);
    return this.http.post<principalModels.RawToken>(`${environment.authUrl}`, body.toString(), options).pipe(
      tap((token) => PrincipalService.setToken(token)),
      catchError((e) => {
        console.error(e);
        this.messageService.add({ severity: 'error', summary: 'Не удалось авторизоваться' });
        return of(e);
      }),
    );
  }
}
