<ng-container *ngIf="registerUserOperation$ | async"></ng-container>

<form [formGroup]="registerUserFormGroup" (ngSubmit)="registerUser()">

  <div class="title">

    Регистрация

  </div>

  <div class="form-item">
    <label>Имя</label>
    <div class="input-container">
      <input
        #name
        type="text"
        placeholder="Имя"
        [formControlName]="registerUserFormControlKeys.Name"
        required>
    </div>
  </div>

  <div class="form-item">
    <label>Пароль</label>
    <div class="input-container">
      <input
        data-testid="password-input"
        #password
        type="password"
        placeholder="Пароль"
        [formControlName]="registerUserFormControlKeys.Password"
        autocomplete="new-password"
        required>
      <button
        type="button"
        class="p-inputgroup-addon"
        (click)="password.type = password.type === 'password' ? 'text' : 'password'">
        <i class="pi" [ngClass]="password.type === 'password' ? 'pi-eye' : 'pi-eye-slash'"></i>
      </button>
    </div>
  </div>

  <div class="form-item">
    <label>Повторите пароль</label>
    <div class="input-container">
      <input
        data-testid="password-input"
        #confirmPassword
        type="password"
        placeholder="Пароль"
        [formControlName]="registerUserFormControlKeys.ConfirmPassword"
        autocomplete="new-password"
        required>
      <button
        type="button"
        class="p-inputgroup-addon"
        (click)="confirmPassword.type = confirmPassword.type === 'password' ? 'text' : 'password'">
        <i class="pi" [ngClass]="confirmPassword.type === 'password' ? 'pi-eye' : 'pi-eye-slash'"></i>
      </button>
    </div>
    <ng-container *ngIf="registerUserFormGroup?.errors?.passwordNotSameError &&
      (registerUserFormGroup.get(registerUserFormControlKeys.ConfirmPassword).touched ||
      registerUserFormGroup.get(registerUserFormControlKeys.ConfirmPassword).dirty)">
      <div class="message error">
        Пароли не совпадают
      </div>
    </ng-container>
  </div>

  <div class="form-control">
    <input
      data-testid="toc-input"
      [id]="registerUserFormControlKeys.TOC"
      type="checkbox"
      [formControlName]="registerUserFormControlKeys.TOC"
      checked
      required>
    <label>
      Я принимаю условия <span class="link" (click)="openUserAgreement()">пользовательского соглашения</span> и <span class="link" (click)="openPrivacyPolicy()">политику конфиденциальности</span>
    </label>
  </div>

  <app-form-actions>

    <button
      data-testid="password-setup-button"
      type="submit"
      class="primary"
      pButton
      [loading]="registerUserOperationInFly$ | async"
      [disabled]="registerUserFormGroup.invalid"
      [label]="'Зарегистрироваться'">
    </button>

  </app-form-actions>

</form>
