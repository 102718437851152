import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '@app/settings.service';
import { environment } from '@environments/environment';
import * as sharedModels from '@shared/models';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as selectModels from '@shared/modules/select/user-select.model';

@Injectable({
  providedIn: 'root',
})
export class DictionaryService implements selectModels.ISelectService<boolean | number> {
  private readonly url: string = `${environment.apiDataManagerUrl}/open-api/dictionary`;

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
  ) {}

  fetchDictValues(dict: string): Observable<sharedModels.DictValue[]> {
    const currentLang = this.settingsService.getCurrentLanguageAsKey();
    return this.http.get<sharedModels.RawDictValue[]>(`${this.url}/${dict}/list`).pipe(
      map((values) =>
        values.map(
          (dictValue) =>
            ({
              id: dictValue.id,
              code: dictValue.code,
              value: dictValue.multiLang[currentLang as keyof sharedModels.RawDictValue['multiLang']],
            }) as sharedModels.DictValue,
        ),
      ),
    );
  }

  fetchDictValueById(dict: sharedModels.GetDictionaryValueByIdParams['dictionaryName'], id: sharedModels.GetDictionaryValueByIdParams['id']): Observable<sharedModels.DictionaryValue> {
    const currentLang = this.settingsService.getCurrentLanguageAsKey();
    return this.http.get<{ id: number; multiLang: sharedModels.Localization }>(`${this.url}/${dict}/${id}`).pipe(
      map(
        (res: { id: number; multiLang: sharedModels.Localization }) =>
          ({
            id: res.id,
            name: res?.multiLang?.[currentLang] ?? '',
          }) as { id: number; name: string },
      ),
    );
  }

  public fetchItemList(body: selectModels.FetchListRequestBody): Observable<selectModels.FetchListResponse<boolean | number>> {
    const currentLang = this.settingsService.getCurrentLanguageAsKey();
    switch ((body as any).dict) {
      case sharedModels.DictionaryList.ObjectLayout:
        return of({
          empty: false,
          list: [
            { label: 'Любая', value: null },
            { label: 'Студия', value: true },
            { label: 'Изолированные комнаты', value: false },
          ],
        });
      default:
        return this.http.get<sharedModels.RawDictValue[]>(`${this.url}/${(body as any).dict}/list`).pipe(
          map((values) => ({
            empty: false,
            list: values.map((dictValue) => ({
              value: dictValue.id,
              label: dictValue.multiLang[currentLang as keyof sharedModels.RawDictValue['multiLang']],
            })),
          })),
        );
    }
  }
}
