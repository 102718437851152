import { Pipe, PipeTransform } from '@angular/core';
import { isObservable, Observable, of } from 'rxjs';
import { map, startWith, catchError } from 'rxjs/operators';

@Pipe({
  name: 'withLoading',
})
export class WithLoadingPipe implements PipeTransform {
  transform<T>(val: T): T | Observable<{ isLoading: boolean; error?: any; value?: any } | any> {
    return isObservable(val)
      ? val.pipe(
          map((value: any) => ({ isLoading: false, value })),
          startWith({ isLoading: true }),
          catchError((error) => of({ isLoading: false, error })),
        )
      : val;
  }
}
