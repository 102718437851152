import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@shared/shared.module';
import { ModalAuthorizationComponent } from './modal-authorization.component';

import { AuthModule as Auth } from '@modules/auth/auth.module';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { InitialComponent } from './components/initial/initial.component';
import { SendCodeComponent } from './components/sign-up/components/send-code/send-code.component';
import { FormActionsComponent } from './components/form-actions/form-actions.component';
import { ForgotPasswordStateComponent } from './components/sign-in/components/forgot-password-state/forgot-password-state.component';
import { SignInStateComponent } from './components/sign-in/components/sign-in-state/sign-in-state.component';
import { RegisterUserStateComponent } from './components/sign-up/components/password-setup-state/register-user-state.component';
import { ResendSmsCodeComponent } from './components/sign-up/components/send-code/components/resend-sms-code/resend-sms-code.component';
// eslint-disable-next-line max-len
import { PasswordRecoveryByPhoneNumberStateComponent } from './components/sign-in/components/forgot-password-state/components/password-recovery-by-phone-number-state/password-recovery-by-phone-number-state.component';
// eslint-disable-next-line max-len
import { RequestVerificationCodeByPhoneNumberStateComponent } from './components/sign-in/components/forgot-password-state/components/password-recovery-by-phone-number-state/components/request-verification-code-by-phone-number-state/request-verification-code-by-phone-number-state.component';
// eslint-disable-next-line max-len
import { ValidateVerificationCodeByPhoneNumberStateComponent } from './components/sign-in/components/forgot-password-state/components/password-recovery-by-phone-number-state/components/validate-verification-code-by-phone-number-state/validate-verification-code-by-phone-number-state.component';
// eslint-disable-next-line max-len
import { PasswordSetupByPhoneNumberStateComponent } from './components/sign-in/components/forgot-password-state/components/password-recovery-by-phone-number-state/components/password-setup-by-phone-number-state/password-setup-by-phone-number-state.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { UserAgreementComponent } from './components/sign-up/components/password-setup-state/components/user-agreement/user-agreement.component';
import { PrivacyPolicyComponent } from './components/sign-up/components/password-setup-state/components/privacy-policy/privacy-policy.component';

@NgModule({
  imports: [CommonModule, SharedModule, Auth, RadioButtonModule],
  declarations: [
    ModalAuthorizationComponent,
    SignUpComponent,
    SignInComponent,
    InitialComponent,
    SendCodeComponent,
    FormActionsComponent,
    ForgotPasswordStateComponent,
    SignInStateComponent,
    RegisterUserStateComponent,
    ResendSmsCodeComponent,
    PasswordRecoveryByPhoneNumberStateComponent,
    RequestVerificationCodeByPhoneNumberStateComponent,
    ValidateVerificationCodeByPhoneNumberStateComponent,
    PasswordSetupByPhoneNumberStateComponent,
    UserAgreementComponent,
    PrivacyPolicyComponent,
  ],
  exports: [ModalAuthorizationComponent],
})
export class AuthModule {}
