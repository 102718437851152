import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CLIENT_ID } from 'const';
import * as principalModels from '@app/principal/models';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

import * as authModels from './auth.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  public authenticate({ login, password }: authModels.Credentials): Observable<principalModels.RawToken> {
    const body = new HttpParams().set('username', login).set('password', password).set('grant_type', 'password').set('client_id', CLIENT_ID);
    return this.http.post<principalModels.RawToken>(`${environment.authUrl}`, body);
  }
}
