import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import * as authModels from '@modules/sign-in/auth.model';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { AuthService } from '@modules/sign-in/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ValidateVerificationCodeByPhoneNumberService {
  constructor(private authService: AuthService) {}

  #validateVerificationCodeLoadingSubject: Subject<boolean> = new Subject<boolean>();
  public validateVerificationCodeLoading$: Observable<boolean> = this.#validateVerificationCodeLoadingSubject.asObservable();

  #validateVerificationCodeOperationSubject: Subject<authModels.Credentials> = new Subject<authModels.Credentials>();
  public validateVerificationCodeOperation$ = this.#validateVerificationCodeOperationSubject.asObservable().pipe(
    tap(() => this.#validateVerificationCodeLoadingSubject.next(true)),
    exhaustMap((payload) => this.#validateVerificationCode(payload)),
    tap(() => this.#validateVerificationCodeLoadingSubject.next(false)),
  );

  public validateVerificationCode(payload: authModels.Credentials): void {
    this.#validateVerificationCodeOperationSubject.next(payload);
  }

  #validateVerificationCode(body: authModels.Credentials): Observable<{ ok: boolean; token?: string; errorCode?: string }> {
    return this.authService.authenticate(body).pipe(
      map((res) => ({ ok: true, token: res.access_token })),
      catchError((e) => {
        return of({ ok: false, errorCode: e?.error?.error_description || 'unknown_error' });
      }),
    );
  }
}
