import { NgModule } from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogModule } from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import { ChipModule } from 'primeng/chip';
import { DropdownModule } from 'primeng/dropdown';
import { GalleriaModule } from 'primeng/galleria';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { ProgressBarModule } from 'primeng/progressbar';
import { MenuModule } from 'primeng/menu';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CalendarModule } from 'primeng/calendar';
import { PaginatorModule } from 'primeng/paginator';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  imports: [
    DynamicDialogModule,
    ChipModule,
    ToastModule,
    TabViewModule,
    ButtonModule,
    InputTextModule,
    DropdownModule,
    InputNumberModule,
    SelectButtonModule,
    AccordionModule,
    GalleriaModule,
    InputMaskModule,
    ProgressBarModule,
    InputSwitchModule,
    CalendarModule,
    MenuModule,
    PaginatorModule,
    MessageModule,
    MessagesModule,
    TooltipModule,
  ],
  exports: [
    DynamicDialogModule,
    ChipModule,
    TabViewModule,
    ToastModule,
    ButtonModule,
    InputTextModule,
    DropdownModule,
    InputNumberModule,
    SelectButtonModule,
    AccordionModule,
    GalleriaModule,
    InputMaskModule,
    ProgressBarModule,
    InputSwitchModule,
    CalendarModule,
    MenuModule,
    PaginatorModule,
    MessageModule,
    MessagesModule,
    TooltipModule,
  ],
  providers: [DialogService, DynamicDialogConfig, MessageService],
})
export class PrimeNgModule {}
