import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DictionarySelectComponent } from './dictionary-select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [DictionarySelectComponent],
  imports: [CommonModule, NgSelectModule, FormsModule],
  exports: [DictionarySelectComponent],
})
export class DictionarySelectModule {}
