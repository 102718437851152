import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { FILE_MANAGER_URI_TOKEN } from 'const';
import { Observable } from 'rxjs';

import * as models from '../models';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(
    @Inject(FILE_MANAGER_URI_TOKEN) private fileManagerURI: string,
    private http: HttpClient,
  ) {}

  public uploadFile(file: File): Observable<models.UploadFileResp> {
    const data = new FormData();
    data.append('file', file);
    return this.http.post<models.UploadFileResp>(`${this.fileManagerURI}/api/upload`, data);
  }

  public deleteFile(uuid: string): Observable<models.DeleteFileResp> {
    return this.http.delete<models.DeleteFileResp>(`${this.fileManagerURI}/api/delete/${uuid}`);
  }

  public getPdfFile(uuid: string): Observable<Blob> {
    return this.http.get(`${this.fileManagerURI}/open-api/download/${uuid}`, { responseType: 'blob' });
  }

  public getInfoFile(uuid: string): Observable<models.GetFileInfoResponse> {
    return this.http.get<models.GetFileInfoResponse>(`${this.fileManagerURI}/open-api/info/${uuid}`);
  }
}
