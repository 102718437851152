import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { MessageService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import * as userModels from '../models';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private http: HttpClient,
    private messageService: MessageService,
  ) {}

  public getAllAchievements(): Observable<userModels.Achievements> {
    return this.http.get<userModels.Achievements>(`${environment.apiUserManagerUrl}/api/achievements/list`).pipe(
      catchError((e) => {
        console.error(e);
        this.messageService.add({ severity: 'error', summary: 'Не удалось найти достижения' });
        return of(e);
      }),
    );
  }

  public getUser(id: userModels.GetUserInfoParams): Observable<userModels.UserInfo> {
    return this.http.get<userModels.UserInfo>(`${environment.apiUserManagerUrl}/open-api/users/${id}`);
  }

  public getUserInfo(id: userModels.GetUserInfoParams): Observable<userModels.UserInfo> {
    return this.http.get<userModels.UserInfo>(`${environment.apiUserManagerUrl}/open-api/agents/agentViewForWebClientApplication/${id}`);
  }

  public getUserProfile(token?: string): Observable<userModels.UserProfile> {
    return token
      ? this.http
          .get<userModels.UserProfile>(`${environment.apiUserManagerUrl}/api/profile`, {
            headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
          })
          .pipe(
            catchError((e) => {
              console.error(e);
              this.messageService.add({ severity: 'error', summary: 'Не удалось авторизоваться' });
              return of(e);
            }),
          )
      : this.http.get<userModels.UserProfile>(`${environment.apiUserManagerUrl}/api/profile`).pipe(
          catchError((e) => {
            console.error(e);
            this.messageService.add({ severity: 'error', summary: 'Не удалось авторизоваться' });
            return of(e);
          }),
        );
  }
}
