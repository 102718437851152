<div class="p-d-flex p-flex-column p-ai-center form-container">

  <div class="p-d-flex p-jc-between header">
    <p class="header--text">Заполните форму</p>
    <button (click)="onCancel()"><i class="pi pi-times"></i></button>
  </div>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="body">
      <div class="p-d-flex p-jc-between" [class.p-flex-column]="isMobile">
         <div class="p-d-flex"
         [ngClass]="{
          'p-flex-row p-jc-between': !isMobile,
          'p-flex-column': isMobile
        }">
           <div class="p-d-flex p-flex-column form-control">
             <label for="clientLogin">Контакты <span class="required">*</span>:</label>
             <app-input-mask
               [unmask]="true"
               placeholder="+7 (___) ___-____"
               formControlName="clientLogin"
               mask="## (000) 000-0000">
             </app-input-mask> 
           </div>
         </div>

        <div class="p-d-flex p-flex-column form-control">
          <label for="clientName">Имя <span class="required">*</span>:</label>
          <input
            id="clientName"
            name="clientName"
            pInputText
            formControlName="clientName"
            autocomplete="off"
            placeholder="Укажите имя"
            required>
        </div>
      </div>
    </div>

    <div class="p-d-flex p-jc-between actions" [class.p-flex-column]="isMobile">
      <button
        pButton
        [disabled]="form.untouched"
        class="clear"
        type="reset"
        (click)="resetForm()"
        label="Очистить">
      </button>

      <button
        pButton
        [loading]="operationInFly"
        class="submit"
        type="submit"
        label="Отправить">
      </button>
    </div>
  </form>
</div>

<ng-template #cityField let-className="className">
  <div class="p-d-flex p-flex-column form-control {{className}}">
    <label>Город <span class="required">*</span>:</label>
    <app-select
        #citySelector
        [disabled]="parentAddressCode === null"
        [initialLoad]="false"
        [clearable]="false"
        [additionalFilter]="{ addressType: parentAddressCode, type: addressType.AllCity }"
        [dataSource]="addressDatasource"
        bindValue="value"
        bindLabel="label"
        placeholder="Выберите город"
        [pageable]="false"
        (selected)="onCitySelect($event)">
    </app-select>
  </div>
</ng-template>