import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

import { FileService } from '@shared/services/file.service';
import { of, Subscription } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
})
export class FileComponent implements OnDestroy {
  @Input() public showOnlyIcon: boolean;

  @Input()
  public readonly id: string;

  @Input()
  public uuid: string;

  @Input()
  public readonly name: string;

  public fileUploadInFly = false;

  public fileDeleteInFly = false;

  @Output() fileUploaded = new EventEmitter();

  @Output() fileDeleted = new EventEmitter();

  #uploadFile$: Subscription;

  #deleteFile$: Subscription;

  constructor(private fileService: FileService) {}

  public uploadFile(files: any): void {
    const file = files[0];
    if (!file) {
      return;
    }
    this.fileUploadInFly = true;
    this.#uploadFile$ = this.fileService
      .uploadFile(file)
      .pipe(
        tap(() => (this.fileUploadInFly = false)),
        filter((res) => !!res?.uuid),
        map((res) => res.uuid),
        tap((uuid) => this.fileUploaded.emit(uuid)),
        catchError((err) => {
          this.fileUploadInFly = false;
          console.warn(err);
          return of(err);
        }),
      )
      .subscribe();
  }

  public deleteFile() {
    if (this.uuid) {
      this.fileDeleteInFly = true;
      this.#deleteFile$ = this.fileService
        .deleteFile(this.uuid)
        .pipe(
          tap(() => (this.fileDeleteInFly = false)),
          filter((res) => !!res),
          tap(() => {
            this.uuid = null;
          }),
          tap(() => this.fileDeleted.emit()),
          catchError((err) => {
            this.fileDeleteInFly = false;
            console.warn(err);
            return of(err);
          }),
        )
        .subscribe();
    }
  }

  ngOnDestroy() {
    if (this.#uploadFile$?.closed === false) this.#uploadFile$.unsubscribe();
  }
}
