import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { PrincipalService } from '@app/principal/service/principal.service';
import { PrivacyPolicyComponent } from '@modules/sign-in/components/sign-up/components/password-setup-state/components/privacy-policy/privacy-policy.component';
import { UserAgreementComponent } from '@modules/sign-in/components/sign-up/components/password-setup-state/components/user-agreement/user-agreement.component';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import * as models from './password-setup-api.model';
import { RegisterUserService } from './register-user.service';

enum RegisterUserFormControlKeys {
  Login = 'login',
  Name = 'name',
  Password = 'password',
  ConfirmPassword = 'confirmPassword',
  TOC = 'toc',
}

@Component({
  selector: 'app-register-user-state',
  templateUrl: './register-user-state.component.html',
  styleUrls: ['./register-user-state.component.scss'],
  providers: [RegisterUserService],
})
export class RegisterUserStateComponent implements OnInit, AfterViewInit {
  @Input()
  public phoneNumber: string;

  @Input()
  public token: string;

  @Output()
  public goNext: EventEmitter<boolean> = new EventEmitter<boolean>();

  public registerUserFormGroup: FormGroup;

  public readonly registerUserFormControlKeys = RegisterUserFormControlKeys;

  public registerUserOperation$: Observable<void>;

  public registerUserOperationInFly$: Observable<boolean>;

  @ViewChild('name')
  private readonly nameInputElement: ElementRef<HTMLInputElement>;

  constructor(
    private formBuilder: FormBuilder,
    private registerUserService: RegisterUserService,
    private principalService: PrincipalService,
    private _dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    this.#initRegisterUserFormGroup();

    this.#initCreateOperation();
  }

  ngAfterViewInit(): void {
    this.nameInputElement.nativeElement.focus();
  }

  #initRegisterUserFormGroup(): void {
    this.registerUserFormGroup = this.formBuilder.group(
      {
        [this.registerUserFormControlKeys.Login]: this.formBuilder.control(this.phoneNumber, [Validators.required]),
        [this.registerUserFormControlKeys.Name]: this.formBuilder.control(null, [Validators.required]),
        [this.registerUserFormControlKeys.Password]: this.formBuilder.control(null, [Validators.required, Validators.pattern(/^[\w&.-@!#$%^&*()_=+-]+$/)]),
        [this.registerUserFormControlKeys.ConfirmPassword]: this.formBuilder.control(null),
        [this.registerUserFormControlKeys.TOC]: this.formBuilder.control(true, [Validators.requiredTrue]),
      },
      {
        validators: this.#checkPassword,
      },
    );
  }

  #initCreateOperation(): void {
    this.registerUserOperation$ = this.registerUserService.registerUser$.pipe(
      tap((profile) => this.principalService.setCurrentUser(profile)),
      tap((res) => res && this.goNext.emit()),
      map(() => void 0),
    );

    this.registerUserOperationInFly$ = this.registerUserService.registerUserOperationInFly$;
  }

  public openUserAgreement(): void {
    this._dialogService.open(UserAgreementComponent, {
      showHeader: true,
    });
  }

  public openPrivacyPolicy(): void {
    this._dialogService.open(PrivacyPolicyComponent, {
      showHeader: true,
    });
  }

  public registerUser(): void {
    this.registerUserService.registerUser({ token: this.token, login: this.registerUserFormGroup.get(this.registerUserFormControlKeys.Login).value, body: this.registerUserPayload });
  }

  private get registerUserPayload(): models.RegisterUserBody {
    const { name, password, toc: sign } = this.registerUserFormGroup.value;
    return {
      name,
      password,
      sign,
    };
  }

  #checkPassword(form: FormGroup): ValidationErrors | null {
    const password = form.get(RegisterUserFormControlKeys.Password);
    const repeatPassword = form.get(RegisterUserFormControlKeys.ConfirmPassword);
    return password.value !== repeatPassword.value ? { passwordNotSameError: true } : null;
  }
}
