import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-phone',
  template: `
    <a href="tel:+7{{ phoneNumber }}" *ngIf="!hidden; else hidden"> +7 {{ phoneNumber | mask: '000 000 00 00' }} </a>
    <ng-template #hidden>
      <a href="tel:+7{{ phoneNumber }}"> Позвонить </a>
    </ng-template>
  `,
  styles: [
    `
      a {
        text-decoration: none;
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneComponent {
  @Input()
  public readonly phoneNumber: string;

  @Input() readonly hidden: boolean = false;
}
