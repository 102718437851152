import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { PhoneIsRegisteredService } from './phone-is-registered.service';
import * as models from './phone-status.model';

export enum InitialFormKeys {
  Login = 'login',
}

@Component({
  selector: 'app-initial',
  templateUrl: './initial.component.html',
  styleUrls: ['./initial.component.scss'],
  providers: [PhoneIsRegisteredService],
})
export class InitialComponent implements OnInit, AfterViewInit {
  @Input()
  public phoneNumber: string = null;

  public readonly initialFormKeys = InitialFormKeys;

  public initialForm: FormGroup;

  public phoneNumberInput$: Observable<string>;
  public getIsPhoneRegisteredStatus$: Observable<void>;
  public getIsPhoneRegisteredLoading$: Observable<boolean>;

  @Output()
  public phoneNumberChange: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public signUpStateChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public signInStateChange: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public accountRestoreStateChange: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public resumeRegisterStateChange: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('login')
  private readonly loginInputElement: ElementRef<HTMLInputElement>;

  constructor(
    private formBuilder: FormBuilder,
    private phoneNumberStatusService: PhoneIsRegisteredService,
  ) {}

  ngOnInit(): void {
    this.#initialForm();
    this.#phoneNumberInput();
    this.#handlePhoneStatus();
  }

  #initialForm(): void {
    this.initialForm = this.formBuilder.group({
      [this.initialFormKeys.Login]: this.formBuilder.control(this.phoneNumber, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
    });
  }

  #phoneNumberInput(): void {
    this.phoneNumberInput$ = this.initialForm.get(this.initialFormKeys.Login).valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      filter((value) => value.length === 10),
      tap((value) => this.phoneNumberChange.emit(value)),
    );
  }

  #handlePhoneStatus(): void {
    this.getIsPhoneRegisteredStatus$ = this.phoneNumberStatusService.isPhoneRegistered$.pipe(
      tap((res) => {
        /**
         *
         * Restore
         *
         * Восстановление удаленной учетки
         *
         * Register
         *
         * Регистрация нового пользователя
         *
         * Authorize
         *
         * Авторизация пользователя
         *
         * hasIdpId = false && isActive = false => register
         *
         * isActive = true
         *
         * isActive = false
         *
         * hasIdpId = true && isActive = true => authorize
         *
         * hasIdpId = false || isActive = false => restore
         *
         */

        switch (res) {
          case models.LoginCheckStatus.Authorize:
            this.signInStateChange.emit();
            break;
          case models.LoginCheckStatus.Register:
            this.signUpStateChange.emit(false);
            break;
          case models.LoginCheckStatus.Restore:
            this.accountRestoreStateChange.emit();
            break;
          case models.LoginCheckStatus.Resume:
            this.signUpStateChange.emit(true);
            break;
        }
      }),
      map(() => void 0),
    );

    this.getIsPhoneRegisteredLoading$ = this.phoneNumberStatusService.getIsPhoneRegisteredLoading$;
  }

  ngAfterViewInit(): void {
    this.loginInputElement.nativeElement.focus();
  }

  public getPhoneNumberStatus(phoneNumber: string = this.login): void {
    this.phoneNumberChange.emit(this.login);
    this.phoneNumberStatusService.getPhoneNumberStatus(phoneNumber);
  }

  get login(): string {
    return this.initialForm.get(this.initialFormKeys.Login).value;
  }
}
