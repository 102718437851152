import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { DeviceService } from '@helpers/device.service';
import { exhaustMap } from 'rxjs/operators';

import * as models from '../models';

@Injectable({
  providedIn: 'root',
})
export class RealPropertyLeadService {
  constructor(
    private deviceService: DeviceService,
    private http: HttpClient,
  ) {}

  public sendRealPropertyLead(body: models.RealPropertyLeadParams) {
    return this.deviceService
      .getDeviceUUID()
      .pipe(exhaustMap((deviceUuid) => this.http.post<models.RealPropertyLeadResp>(`${environment.apiDataManagerUrl}/open-api/leads/saveLeadFromWebSite`, { ...body, deviceUuid })));
  }
}
