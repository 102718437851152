import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-yandex-map',
  templateUrl: './yandex-map.component.html',
  styleUrls: ['./yandex-map.component.scss'],
})
export class YandexMapComponent {
  @Input()
  public readonly width: string = '500px';

  @Input() coordinates: any[];
}
