import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { exhaustMap, tap } from 'rxjs/operators';
import { RequestCodeApiService } from '@modules/sign-in/request-code-api.service';

@Injectable({
  providedIn: 'root',
})
export class RequestCodeService {
  $requestCodeLoadingSubject = new ReplaySubject<boolean>(1);
  requestCodeLoading$ = this.$requestCodeLoadingSubject.asObservable();

  $requestCodeSubject: Subject<string> = new ReplaySubject<string>(1);
  requestCode$ = this.$requestCodeSubject.asObservable().pipe(
    tap(() => this.$requestCodeLoadingSubject.next(true)),
    exhaustMap((login) => this.requestCodeApiService.getVerificationCode({ login })),
    tap(() => this.$requestCodeLoadingSubject.next(false)),
  );

  constructor(private requestCodeApiService: RequestCodeApiService) {}

  public requestCode(login: string) {
    this.$requestCodeSubject.next(login);
  }
}
