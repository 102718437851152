<ng-template [ngIf]="currentUser">
  <div class="p-d-flex profile-block">
    <img
      class="avatar-circle" (click)="menu.toggle($event)"
      [src]="currentUser.photoUuid ? avatarUrl : 'assets/images/user-icon.svg'"
      alt="avatar"
      (error)="onImageDownloadError($event)">
    <p-menu #menu [popup]="true" [model]="itemsUserIn"></p-menu>
    <!--    <img src="assets/images/settings.svg">-->
    <!--    <img src="assets/images/notification.svg">-->
  </div>
</ng-template>
<ng-template [ngIf]="!currentUser">
  <div class="p-d-flex profile-block">
    <img
      class="avatar-circle" (click)="menu.toggle($event)"
      src="assets/images/user-icon.svg"
      alt="avatar">
    <p-menu #menu [popup]="true" [model]="itemsUserOut"></p-menu>
  </div>
</ng-template>
