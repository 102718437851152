import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { SettingsService } from '@app/settings.service';

import * as sharedModels from '@shared/models';

@Component({
  template: '<span>{{value}}</span>',
  selector: 'app-name',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      span {
        white-space: pre-wrap;
      }
    `,
  ],
})
export class NameComponent implements OnInit {
  @Input()
  public readonly name: sharedModels.Localization | any;

  public value: string;

  constructor(private settingsService: SettingsService) {}

  ngOnInit() {
    const currentLanguageAsKey = this.settingsService.getCurrentLanguageAsKey();
    if (this.name) {
      this.value = this.name?.[currentLanguageAsKey];
    }
  }
}
