import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Observable, iif, of } from 'rxjs';

import { PrincipalService } from '@app/principal/service/principal.service';
import { map, mergeMap, tap } from 'rxjs/operators';
import * as models from './password-setup.model';

import { SetupPasswordService } from '../../setup-password.service';
import { AccountRestoreService } from './account-restore.service';

export enum PasswordRecoveryByPhoneNumberFormControlKeys {
  PhoneNumber = 'phoneNumber',
  Password = 'password',
  ConfirmPassword = 'confirmPassword',
}

@Component({
  selector: 'app-password-setup-by-phone-number-state',
  templateUrl: './password-setup-by-phone-number-state.component.html',
  styleUrls: ['./password-setup-by-phone-number-state.component.scss'],
  providers: [SetupPasswordService],
})
export class PasswordSetupByPhoneNumberStateComponent implements OnInit, AfterViewInit {
  @Input()
  public accountRestore: boolean;

  @Input()
  public phoneNumber: string;

  @Input()
  public token: string;

  @Output()
  public closePassSetup: EventEmitter<void> = new EventEmitter<void>();

  public passwordSetupForm: FormGroup;
  public readonly passwordSetupFormControlKeys = PasswordRecoveryByPhoneNumberFormControlKeys;

  public errorCode: string;

  public setupPasswordOperation$: Observable<void>;
  public setupPasswordOperationInFly$: Observable<boolean>;

  @ViewChild('password')
  private passwordInputElement: ElementRef<HTMLInputElement>;

  constructor(
    private formBuilder: FormBuilder,
    private setupPasswordService: SetupPasswordService,
    private principalService: PrincipalService,
    private accountRestoreService: AccountRestoreService,
  ) {}

  ngOnInit(): void {
    this.#initPasswordRecoveryForm();

    this.#initSetupPassword();
  }

  ngAfterViewInit(): void {
    this.passwordInputElement.nativeElement.focus();
  }

  public setPassword(): void {
    this.setupPasswordService.setupPassword({ token: this.token, credentials: { login: this.payload.phoneNumber, password: this.payload.password } });
  }

  public get errorCodeText(): string {
    switch (this.errorCode) {
      case 'email_not_found':
        return 'Учетная запись с указанным email не найдена';
      default:
        return 'Не удалось проверить код';
    }
  }

  #initPasswordRecoveryForm(): void {
    this.passwordSetupForm = this.formBuilder.group(
      {
        [this.passwordSetupFormControlKeys.PhoneNumber]: this.formBuilder.control(this.phoneNumber, [Validators.required]),
        [this.passwordSetupFormControlKeys.Password]: this.formBuilder.control(null, [Validators.required]),
        [this.passwordSetupFormControlKeys.ConfirmPassword]: this.formBuilder.control(null),
      },
      {
        validators: this.#checkPassword,
        updateOn: 'blur',
      },
    );
  }

  #initSetupPassword(): void {
    this.setupPasswordOperation$ = this.setupPasswordService.setupPassword$.pipe(
      tap((profile) => this.principalService.setCurrentUser(profile)),
      mergeMap(() => iif(() => this.accountRestore === true, this.accountRestoreService.restore(), of(null))),
      tap(() => this.closePassSetup.emit()),
      map(() => void 0),
    );

    this.setupPasswordOperationInFly$ = this.setupPasswordService.setupPasswordOperationInFly$;
  }

  private get payload(): models.PasswordSetupPayload {
    return this.passwordSetupForm.value;
  }

  #checkPassword(form: FormGroup): ValidationErrors | null {
    const password = form.get(PasswordRecoveryByPhoneNumberFormControlKeys.Password);
    const repeatPassword = form.get(PasswordRecoveryByPhoneNumberFormControlKeys.ConfirmPassword);
    return password.value !== repeatPassword.value ? { passwordNotSameError: true } : null;
  }
}
