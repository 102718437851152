import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@shared/shared.module';
import { AuthModule } from '@modules/sign-in/auth.module';

import { AuthStateComponent } from './auth-state/auth-state.component';

@NgModule({
  imports: [CommonModule, SharedModule, AuthModule],
  declarations: [AuthStateComponent],
  exports: [AuthStateComponent],
})
export class PrincipalModule {}
