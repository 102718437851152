import { Component, OnDestroy, OnInit } from '@angular/core';
import { UtilService } from '@helpers/util.service';
import { ModalAuthorizationComponent } from '@modules/sign-in/modal-authorization.component';
import * as userModels from '@modules/user/models';
import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { PrincipalService } from '../service/principal.service';

@Component({
  selector: 'app-auth-state',
  templateUrl: './auth-state.component.html',
  styleUrls: ['./auth-state.component.scss'],
})
export class AuthStateComponent implements OnInit, OnDestroy {
  public currentUser: userModels.UserProfile;
  private destroy$ = new Subject<void>();

  public get avatarUrl(): string {
    return this.util.getFilePath() + this.currentUser?.photoUuid;
  }

  public itemsUserIn: MenuItem[] = [
    {
      label: null,
      routerLink: '/profile',
    },
    {
      label: 'Личный кабинет',
      routerLink: '/account',
    },
    {
      label: 'Мои заявки',
      routerLink: '/my-application-list',
    },
    {
      label: 'Избранные объекты',
      routerLink: '/favourites',
    },
    {
      label: 'Сохраненные поиски',
      routerLink: '/search-settings',
    },
    // TODO: временно, пока нет постановки
    // {
    //   label: 'Найти специалиста',
    // },
    {
      label: 'Выйти',
      command: () => {
        this.logout();
      },
    },
  ];

  public itemsUserOut: MenuItem[] = [
    {
      label: 'Вход',
      command: () => {
        this.login();
      },
    },
    {
      label: 'Избранные объекты',
      routerLink: '/favourites',
    },
    {
      label: 'Сохраненные поиски',
      routerLink: '/search-settings',
    },
  ];

  #currentUser$: Subscription;

  constructor(
    private principalService: PrincipalService,
    private _dialogService: DialogService,
    private util: UtilService,
  ) {}

  ngOnInit(): void {
    this.#currentUser$ = this.principalService.currentUserSubject$
      .pipe(
        tap((user) => (this.currentUser = user)),
        filter((user) => !!user),
        tap((user) => (this.itemsUserIn[0].label = `${user.name} ${user.surname ?? ''}`)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  public login(): void {
    this._dialogService.open(ModalAuthorizationComponent, {
      showHeader: false,
      styleClass: 'modal-authorization-desktop',
    });
  }

  public logout(): void {
    this.principalService.logout();
  }

  public onImageDownloadError(event: Event): void {
    (event.target as HTMLImageElement).src = 'assets/images/user-icon.svg';
  }

  ngOnDestroy(): void {
    if (this.#currentUser$?.closed === false) this.#currentUser$.unsubscribe();

    this.destroy$.next();
    this.destroy$.complete();
  }
}
