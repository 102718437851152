import { Component, Input } from '@angular/core';
import { Params, Router } from '@angular/router';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})
export class LinkComponent {
  @Input()
  public readonly link: string = null;

  @Input()
  public readonly id: number | string = null;

  @Input()
  public readonly queryParams: Params = null;

  constructor(private _router: Router) {}

  openLink() {
    const url = this._router.serializeUrl(this._router.createUrlTree([this.link, this.id], { queryParams: this.queryParams }));
    window.open(url, '_blank');
  }
}
