<ng-select
  #dictionaryValueSelector
  class="control"
  [labelForId]="id"
  [id]="id"
  [bindValue]="bindValue"
  [multiple]="multiple"
  [loading]="loading"
  loadingText="Загрузка..."
  [items]="dictionaryValueList"
  [class]="className"
  [placeholder]="placeholder"
  (open)="onOpen()"
  (change)="onChange($event)"
  bindValue="value"
  bindLabel="label"
  [(ngModel)]="selectedValue"
  [title]="title">
</ng-select>
