import { Injectable } from '@angular/core';
import * as principalModels from '@app/principal/models';
import { PrincipalService } from '@app/principal/service/principal.service';
import { ProfileService } from '@modules/sign-in/components/sign-up/components/password-setup-state/profile.service';
import * as userModels from '@modules/user/models';
import { iif, Observable, of, pipe, Subject } from 'rxjs';
import { catchError, exhaustMap, mergeMap, switchMap, tap } from 'rxjs/operators';
import * as models from '../../../../auth.model';
import { SignInApiService } from './sign-in-api.service';

@Injectable({
  providedIn: 'root',
})
export class SignInService {
  constructor(
    private signInApiService: SignInApiService,
    private principalService: PrincipalService,
    private profileService: ProfileService,
  ) {}

  #signInOperationInFlySubject = new Subject<boolean>();
  public signInOperationInFly$ = this.#signInOperationInFlySubject.asObservable();

  #signInSubject: Subject<models.Credentials> = new Subject<models.Credentials>();
  public signIn$: Observable<userModels.UserProfile | string> = this.#signInSubject.asObservable().pipe(
    tap(() => this.#signInOperationInFlySubject.next(true)),
    exhaustMap((credentials) => this.#login(credentials).pipe(mergeMap((res) => iif(() => typeof res === 'string', of(res as string), of(res).pipe(this.#handleSuccess()))))),
  );

  #handleSuccess() {
    return pipe(
      tap((token) => PrincipalService.setToken(token as principalModels.RawToken)),
      switchMap(() => this.profileService.getUserProfile().pipe(tap((profile) => this.principalService.setCurrentUser(profile)))),
    );
  }

  public signIn(credentials: models.Credentials) {
    this.#signInSubject.next(credentials);
  }

  #login(credentials: models.Credentials): Observable<principalModels.RawToken | string> {
    return this.signInApiService.signIn(credentials).pipe(
      tap(() => this.#signInOperationInFlySubject.next(false)),
      catchError((e) => {
        this.#signInOperationInFlySubject.next(false);

        if (e.status === 401 && e?.error?.error_description) {
          return of(e.error.error_description);
        }

        return of(e);
      }),
    );
  }
}
