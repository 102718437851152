import { Component, Input, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { SettingsService } from '@app/settings.service';

@Component({
  selector: 'app-real-property-details-link',
  templateUrl: './real-property-details-link.component.html',
  styleUrls: ['./real-property-details-link.component.scss'],
})
export class RealPropertyDetailsLinkComponent implements OnInit {
  constructor(private _settingsService: SettingsService) {}
  @Input()
  public readonly id: number = null;

  @Input()
  public readonly queryParams: Params = { tab: 'same_objects' };

  ngOnInit() {
    if (this._settingsService.numberAgentSession) {
      this.queryParams.info = this._settingsService.numberAgentSession;
    }
  }
}
