import * as fm from 'generated/api/fm.api';

type UploadFile = fm.paths['/api/upload'];
export type UploadFileParams = UploadFile['post']['parameters']['formData']['file'];
export type UploadFileResp = UploadFile['post']['responses']['200']['schema'];

type DeleteFile = fm.paths['/api/delete/{uuid}'];
export type DeleteFileResp = DeleteFile['delete']['responses']['200']['schema'];

type GetFileInfo = fm.paths['/api/info/{uuid}'];
export type GetFileInfoResponse = GetFileInfo['get']['responses']['200']['schema'];
