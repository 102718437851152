import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { v4 as UUIDv4, validate as validateUUID } from 'uuid';

import * as um from 'generated/api/um.api';
import { USER_MANAGER_URI } from 'const';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  static readonly DEVICE_UUID_LOCAL_STORAGE_KEY: string = 'device-uuid';

  constructor(
    @Inject(USER_MANAGER_URI) private readonly userManagerURI: string,
    private http: HttpClient,
  ) {}

  public getDeviceUUID(): Observable<string> {
    const deviceUUID = localStorage.getItem(DeviceService.DEVICE_UUID_LOCAL_STORAGE_KEY);
    return validateUUID(deviceUUID) ? of(deviceUUID) : this.#registerDevice();
  }

  public connectDeviceToUserAccount(): Observable<boolean> {
    return this.getDeviceUUID().pipe(switchMap((uuid) => this.#connectDevice(uuid)));
  }

  public disconnectDeviceFromUserAccount(token: string): Observable<boolean> {
    return this.getDeviceUUID().pipe(switchMap((uuid) => this.#disconnectDevice(uuid, token)));
  }

  #registerDevice(): Observable<string> {
    const deviceUUID = UUIDv4();

    const { name: platform, version } = getBrowserInfo();

    const data: AddDeviceUUIDParams = {
      uuid: deviceUUID,
      platform,
      version,
    };

    return this.http.post<AddDeviceUUIDResp>(`${this.userManagerURI}/open-api/profile-config/addDevice`, data).pipe(
      map((res) => res.uuid),
      filter((uuid) => uuid !== null),
      tap((uuid) => localStorage.setItem(DeviceService.DEVICE_UUID_LOCAL_STORAGE_KEY, uuid)),
    );
  }

  #connectDevice(uuid: ConnectDeviceParams): Observable<boolean> {
    return this.http.post<ConnectDeviceResp>(`${this.userManagerURI}/api/users/connectDevice?deviceUuid=${uuid}`, null).pipe(map((res) => res?.success));
  }

  #disconnectDevice(uuid: DisconnectDeviceParams, token: string): Observable<boolean> {
    return this.http
      .post<DisconnectDeviceResp>(`${this.userManagerURI}/api/users/disableDevice?deviceUuid=${uuid}`, null, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      })
      .pipe(map((res) => res?.success));
  }
}

function getBrowserInfo() {
  const ua = navigator.userAgent;
  let tem;
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: 'IE', version: tem[1] || '' };
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR|Edge\/(\d+)/);
    if (tem != null) {
      return { name: 'Opera', version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  // tslint:disable-next-line:no-conditional-assignment
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: M[0],
    version: M[1],
  };
}

type AddDeviceUUID = um.paths['/open-api/profile-config/addDevice'];
type AddDeviceUUIDParams = AddDeviceUUID['post']['parameters']['body']['dto'];
type AddDeviceUUIDResp = AddDeviceUUID['post']['responses']['200']['schema'];

type ConnectDevice = um.paths['/api/users/connectDevice'];
type ConnectDeviceParams = ConnectDevice['post']['parameters']['query']['deviceUuid'];
type ConnectDeviceResp = ConnectDevice['post']['responses']['200']['schema'];

type DisconnectDevice = um.paths['/api/users/disableDevice'];
type DisconnectDeviceParams = DisconnectDevice['post']['parameters']['query']['deviceUuid'];
type DisconnectDeviceResp = DisconnectDevice['post']['responses']['200']['schema'];
