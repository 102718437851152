import { Localization } from '@shared/models/localization';

import * as dm from 'generated/api/dm.api';

export interface DictValue {
  id: number;
  code: string;
  value: string;
}

export interface RawDictValue {
  id: number;
  code: string;
  multiLang: Localization;
}

export enum DictionaryList {
  OperationType = 'OperationType',
  ObjectType = 'ObjectType',
  HouseCondition = 'HouseCondition',
  ObjectLayout = 'ObjectLayout',
}

type GetDictionaryValueById = dm.paths['/open-api/dictionary/{dictionaryName}/{id}'];
export type GetDictionaryValueByIdParams = GetDictionaryValueById['get']['parameters']['path'];
export type DictionaryValueById = GetDictionaryValueById['get']['responses']['200']['schema'];
export type DictionaryValue = { id: number; name: string };
