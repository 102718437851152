import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { exhaustMap, tap } from 'rxjs/operators';
import { RequestCodeApiService } from '@modules/sign-in/request-code-api.service';

@Injectable({
  providedIn: 'root',
})
export class RequestVerificationCodeByPhoneNumberService {
  constructor(private passwordRecoveryByEmail: RequestCodeApiService) {}

  #getVerificationCodeLoadingSubject = new Subject<boolean>();
  public getVerificationCodeLoading$ = this.#getVerificationCodeLoadingSubject.asObservable();

  #getVerificationCodeSubject = new Subject<string>();
  public verificationCode$ = this.#getVerificationCodeSubject.asObservable().pipe(
    tap(() => this.#getVerificationCodeLoadingSubject.next(true)),
    exhaustMap((email) => this.#getVerificationCode(email)),
    tap(() => this.#getVerificationCodeLoadingSubject.next(false)),
  );

  public getCode(phone: string): void {
    this.#getVerificationCodeSubject.next(phone);
  }

  #getVerificationCode(phone: string) {
    return this.passwordRecoveryByEmail.getVerificationCode({ login: phone });
  }
}
