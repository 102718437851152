import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { SettingsService } from '@app/settings.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ReplaySubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { FetchListRequestBody, ISelectService } from './user-select.model';

export type ListItem = { value: string; label: string };

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public readonly disabled: boolean = false;

  @Input()
  public readonly searchable: boolean = false;

  @Input()
  public readonly clearable: boolean = true;

  public loading = false;

  @Input()
  public readonly formControl: AbstractControl = null;

  @Input()
  public selectedItem: ListItem = { value: null, label: null };

  @Input()
  public selectedValue: any = null;

  @Input()
  public items: any[] = [];

  @Input()
  public readonly pageable: boolean = true;

  @Input()
  public itemList: ListItem[] = [];

  @ViewChild('userSelector', { static: true }) private userSelector: NgSelectComponent;

  @Input()
  public dataSource: ISelectService<any> = null;

  @Input()
  public initialLoad = false;

  @Input()
  public readonly bindValue: string = 'value';

  @Input()
  public readonly bindLabel: string = 'label';

  @Input()
  public readonly placeholder: string = 'Пользователь';

  @Input()
  public readonly multiple: boolean = false;

  @Input()
  public readonly class: string = 'control';

  @Input()
  public readonly noFoundText: string = 'Данные не найдены';

  @Input()
  public readonly additionalFilter: any = null;

  @Output()
  public openList = new EventEmitter<void>();

  @Output()
  public pageChange = new EventEmitter<void>();

  @Output()
  public initSearch = new EventEmitter<string>();

  @Output()
  public selected = new EventEmitter<string | string[] | number | number[]>();

  public get value() {
    return this.selectedItem?.value ?? this.selectedValue ?? null;
  }

  public set value(value) {
    if (this.selectedItem?.value) {
      this.selectedItem.value = value;
    } else if (this.selectedValue) {
      this.selectedItem.value = this.selectedValue;
    }
  }

  #pageNumber = 0;
  #pageSize = 10;
  #searchTerm: string = null;
  #emptyList: boolean = null;

  #searchTerm$: Subscription;
  #itemList$: Subscription;
  #searchTermSubject: ReplaySubject<string> = null;
  #nextListSubject: ReplaySubject<number> = null;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _settingsService: SettingsService,
  ) {}

  ngOnInit(): void {
    if (this.selectedItem?.value) {
      this.itemList = [...this.itemList, this.selectedItem];
      this.selectedItem = this.itemList[0];
    }

    if (this.initialLoad) {
      this.onOpen();
    }

    this.subscribeClear();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedItem?.firstChange === false && changes.selectedItem?.previousValue !== changes.selectedItem?.currentValue) {
      this.itemList = [...this.itemList, changes.selectedItem.currentValue];
      this.selectedItem = changes.selectedItem.currentValue;
    }
  }

  subscribeClear() {
    this._settingsService.clearComplex$.subscribe((isOnlyAddress) => {
      if (!isOnlyAddress) {
        this.clear();
      }
    });
  }

  public onOpen(): void {
    this.#pageNumber = 0;
    this.#onInit();
  }

  public onNextPage(): void {
    this.#onPageChange();
    this.#nextListSubject.next(this.#pageNumber++);
  }

  public onSearch(event: { term: string }): void {
    this.#onSearch();
    this.#searchTermSubject.next(event.term);
  }

  public refreshList(): void {
    this.itemList = [];
    this.userSelector.handleClearClick();
    this.#fetchList();
  }

  #fetchList(): void {
    if (this.dataSource) {
      this.#itemList$ = this.dataSource
        .fetchItemList({ pageNumber: this.#pageNumber, pageSize: this.#pageSize, ...this.additionalFilter })
        .pipe(
          tap((res) => {
            this.#pageNumber++;
            this.itemList = this.itemList.concat(res.list);
            this.loading = false;
            this._cdr.detectChanges();
          }),
        )
        .subscribe();
    } else {
      console.warn('Datasource not provided');
    }
  }

  public onChange(event: any): void {
    Object.prototype.toString.call(event) === '[object Array]'
      ? this.selected.emit((event as ListItem[]).map((item) => (this.bindValue ? item[this.bindValue] : item)))
      : this.selected.emit(event ? (this.bindValue ? event[this.bindValue] : event) : null);
  }

  public clear(): void {
    this.userSelector.clearModel();
  }

  #onInit(): void {
    if (this.itemList.length === 0 || this.itemList.length === 1) {
      this.loading = true;

      this.#fetchList();
    }
  }

  fetchItems(filter: FetchListRequestBody, isFromPageChange = true) {
    return this.dataSource.fetchItemList(filter).pipe(
      tap((res) => {
        if (isFromPageChange) {
          this.#emptyList = res.empty;
          this.itemList = this.itemList.concat(res.list);
        } else {
          this.itemList = res.list;
        }
        this.loading = false;
        this._cdr.detectChanges();
      }),
    );
  }

  #onPageChange(): void {
    if (this.pageable && this.#nextListSubject === null) {
      this.#nextListSubject = new ReplaySubject<number>(1);
      this.#nextListSubject
        .pipe(
          filter(() => !this.#emptyList),
          tap(() => (this.loading = true)),
          switchMap((pageNumber) => this.fetchItems({ pageNumber, pageSize: this.#pageSize, searchData: this.#searchTerm, ...this.additionalFilter })),
        )
        .subscribe();
    }
  }

  #onSearch() {
    if (this.#searchTermSubject === null) {
      this.#searchTermSubject = new ReplaySubject<string>();

      this.loading = true;
      this.#searchTermSubject
        .pipe(
          debounceTime(400),
          filter((term) => term !== null),
          map((term: string) => term.trim()),
          distinctUntilChanged(),
          tap((term) => {
            this.#searchTerm = term;
            this.loading = true;
          }),
          switchMap((searchData) => this.fetchItems({ pageNumber: 0, pageSize: this.#pageSize, searchData, ...this.additionalFilter }, false)),
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    if (this.#searchTerm$?.closed === false) {
      this.#searchTerm$.unsubscribe();
    }

    if (this.#itemList$?.closed === false) {
      this.#itemList$.unsubscribe();
    }

    if (this.#searchTerm$?.closed === false) {
      this.#searchTerm$.unsubscribe();
    }

    if (this.#nextListSubject?.closed === false) {
      this.#nextListSubject.unsubscribe();
    }
  }
}
