import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as models from './password-setup-api.model';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RegisterUserApiService {
  constructor(private http: HttpClient) {}

  public registerUser(token: string, body: models.RegisterUserBody): Observable<models.RegisterUserResp> {
    return this.http.post(`${environment.apiUserManagerUrl}/api/users/registration`, body, { headers: new HttpHeaders().set('Authorization', `Bearer ${token}`) });
  }
}
