import { InjectionToken } from '@angular/core';

export const JWT_TOKEN_KEY = 'token';

export const CURRENT_USER_KEY = 'currentUser';

export const CLIENT_ID = 'htc';

export const VIEW_MANAGER_URI = new InjectionToken('viewManagerURI');

export const USER_MANAGER_URI = new InjectionToken('userManagerURI');

export const DATA_MANAGER_URI = new InjectionToken('dataManagerURI');

export const FILE_MANAGER_URI_TOKEN = new InjectionToken<string>('fileManagerURI');

export const KAZAKHSTAN_COUNTRY_TOKEN = new InjectionToken<number>('kazakhstanCountry');

export const CYPRUS_COUNTRY_TOKEN = new InjectionToken<number>('cyprusCountry');

export const OBJECT_TYPE_ID_TOKEN = new InjectionToken<OBJECT_TYPE_ID>('objectTypeId');

export const SLOGAN = 'маркетплейс видеообзоров и хороших риелторов';

export enum OBJECT_TYPE_ID {
  Buy = 1,
  Sell = 2,
}

export const CITY_KEY = 'city';

export const NUMBER_AGENT_KEY = 'numberAgentSession';

export const IS_HIDE_CALL_CENTER_NUMBER = 'isHideCallCenterNumber';

export const MAX_WIDTH_MOBILE = 600;

export const CURRENCY_SYMBOL = {
  uzbekistan: 'сум',
  kazakhstan: '₸',
};
