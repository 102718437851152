import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as authModels from '../../../../auth.model';
import { AuthService } from '../../../../auth.service';
import * as principalModels from '@app/principal/models';

@Injectable({
  providedIn: 'root',
})
export class SignInApiService {
  constructor(private authService: AuthService) {}

  public signIn(credentials: authModels.Credentials): Observable<principalModels.RawToken> {
    return this.authService.authenticate(credentials);
  }
}
