<div class="image">
  <ng-container [ngSwitch]="hasImage">
    <ng-container *ngSwitchCase="false">
      <ng-container [ngSwitch]="hasVideo">
        <ng-container *ngSwitchCase="true">
          <ng-container *ngIf="imageThumbnailNotFound === false">
            <ng-container *ngTemplateOutlet="imageTmpl; context: { image: { src: videoThumbnail, type: 'thumbnail' } }"></ng-container>
          </ng-container>
          <ng-container *ngIf="imageThumbnailNotFound === true">
            <ng-container *ngTemplateOutlet="noImageTmpl"></ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="false">
          <ng-container *ngTemplateOutlet="noImageTmpl"></ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="true">
      <ng-container *ngIf="imageNotFound === false">
        <ng-container *ngTemplateOutlet="imageTmpl; context : { image: { src: imagePath, type: 'image' } }"></ng-container>
      </ng-container>
      <ng-container *ngIf="imageNotFound === true">
        <ng-container *ngTemplateOutlet="noImageTmpl"></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #imageTmpl let-image='image'>
  <div class="image">
    <img
      [src]="image.src"
      [alt]="image.type"
      (error)="onImageDownloadError(image.type)">
  </div>
</ng-template>

<ng-template #noImageTmpl>
  <app-no-photo
    [isMainPhoto]="false">
  </app-no-photo>
</ng-template>
