import { Platform } from '@angular/cdk/platform';
import { Component } from '@angular/core';
import { environment } from '@environments/environment';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

export enum AuthStateList {
  Initial = 'initial',
  SignIn = 'signIn',
  SignUp = 'signUp',
}

@Component({
  templateUrl: './modal-authorization.component.html',
  styleUrls: ['./modal-authorization.component.scss'],
})
export class ModalAuthorizationComponent {
  public readonly authStateList = AuthStateList;
  public authState: AuthStateList = AuthStateList.Initial;
  public phoneNumber: string = null;
  public resumeRegister = false;
  public accountRestore = false;

  constructor(
    private platform: Platform,
    private _dialogRef: DynamicDialogRef,
  ) {}

  public goBack(): void {
    this.authState = AuthStateList.Initial;
  }

  public onPhoneNumberChange(phoneNumber: string): void {
    this.phoneNumber = phoneNumber;
  }

  public onSignUp(resumeRegister: boolean): void {
    this.resumeRegister = resumeRegister;
    this.authState = AuthStateList.SignUp;
  }

  public onSignIn(): void {
    this.authState = AuthStateList.SignIn;
  }

  public onAccountRestore(): void {
    this.accountRestore = true;
    this.authState = AuthStateList.SignIn;
  }

  public onClose(): void {
    this._dialogRef.close();
  }

  public openApp(): void {
    if (this.platform.IOS) {
      window.location.href = environment.appAppStoreLink;
    } else {
      window.location.href = environment.appPlayMarketLink;
    }
  }
}
