export const environment = {
  production: true,
  authUrl: 'https://idp.jurta.kz/auth/realms/htc/protocol/openid-connect/token',
  apiDataManagerUrl: 'https://dm.jurta.kz',
  apiFileManagerUrl: 'https://cdn-fm.jurta.kz',
  apiViewManagerUrl: 'https://vm.jurta.kz',
  apiUserManagerUrl: 'https://um.jurta.kz',
  apiGisManagerUrl:  'https://gm.jurta.kz',
  apiEducationManagerUrl: 'https://em.jurta.kz',
  apiVideoUrl:  'https://cdn.jurta.kz/',
  appPlayMarketLink: 'https://play.google.com/store/apps/details?id=kz.jurta.app&hl=ru&gl=US',
  appAppStoreLink: 'https://apps.apple.com/ua/app/jurta/id1595532580?l=ru',


  appDefaultSettings: {
    countries: {
      kazakhstan: {
        id: 3211,
        code: '001',
        name: {
          nameRu: 'Казахстан',
          nameKz: 'Қазақстан',
          nameEn: 'Kazakhstan',
        },
      },
      cyprus: {
        id: 3212,
        code: '002',
        name: {
          nameRu: 'Кипр',
          nameKz: 'Кипр',
          nameEn: 'Кипр',
        },
      },
    },
    cities: {
      kazakhstan: 1,
      cyprus: 3165,
    },
  },
};