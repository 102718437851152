import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export enum PasswordRecoveryByPhoneNumberStateList {
  RequestVerificationCode = 'requestVerificationCode',
  ValidateVerificationCode = 'validateVerificationCode',
  PasswordSetup = 'passwordSetup',
}

@Component({
  selector: 'app-password-recovery-by-phone-number-state',
  templateUrl: './password-recovery-by-phone-number-state.component.html',
  styleUrls: ['./password-recovery-by-phone-number-state.component.scss'],
})
export class PasswordRecoveryByPhoneNumberStateComponent implements OnInit {
  @Input()
  public accountRestore: boolean;

  @Input()
  public phoneNumber: string;

  public token: string;

  @Output()
  public closePassRecovery: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public goBackFromPassRecovery: EventEmitter<void> = new EventEmitter<void>();

  public passwordRecoveryState: PasswordRecoveryByPhoneNumberStateList;
  public readonly passwordRecoveryStateList = PasswordRecoveryByPhoneNumberStateList;

  ngOnInit(): void {
    this.passwordRecoveryState = PasswordRecoveryByPhoneNumberStateList.RequestVerificationCode;
  }

  public goToValidateCodeState(phoneNumber: string): void {
    this.phoneNumber = phoneNumber;
    this.passwordRecoveryState = this.passwordRecoveryStateList.ValidateVerificationCode;
  }

  public goToPasswordSetupState(token: string): void {
    this.token = token;
    this.passwordRecoveryState = this.passwordRecoveryStateList.PasswordSetup;
  }

  public close(): void {
    this.closePassRecovery.emit();
  }

  public goBack(): void {
    this.goBackFromPassRecovery.emit();
  }
}
