import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

import * as models from '@modules/sign-in/auth.model';
import { map, tap } from 'rxjs/operators';
import { RequestVerificationCodeByPhoneNumberService } from './request-verification-code-by-phone-number.service';

enum RequestCodeFormControlKeys {
  PhoneNumber = 'phoneNumber',
}

@Component({
  selector: 'app-request-verification-code-by-phone-number-state',
  templateUrl: './request-verification-code-by-phone-number-state.component.html',
  styleUrls: ['./request-verification-code-by-phone-number-state.component.scss'],
  providers: [RequestVerificationCodeByPhoneNumberService],
})
export class RequestVerificationCodeByPhoneNumberStateComponent implements OnInit {
  @Input()
  public accountRestore: boolean;

  @Input()
  public phoneNumber: string;

  @Output()
  public goNext: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public goBackFromRequestVerification: EventEmitter<void> = new EventEmitter<void>();

  public errorCode: string;
  public errorMessage: string;

  public forgotPasswordFormGroup: FormGroup;
  public readonly forgotPasswordFormControlKeys = RequestCodeFormControlKeys;

  public getVerificationCodeOperation$: Observable<void>;
  public getVerificationCodeLoading$: Observable<boolean>;

  constructor(
    private formBuilder: FormBuilder,
    private requestVerificationCodeService: RequestVerificationCodeByPhoneNumberService,
  ) {}

  ngOnInit(): void {
    this.#initForgotPassword();

    this.#initGetVerificationOperation();
  }

  public requestCode(): void {
    this.requestVerificationCodeService.getCode(this.phoneNumber);
  }

  public goBack(): void {
    this.goBackFromRequestVerification.emit();
  }

  public get errorCodeText() {
    switch (this.errorCode) {
      case 'phone_number_not_found':
        return 'Не найдена учетная запись на указанный номер телефона';
      default:
        return 'Не удалось отправить код';
    }
  }

  #initForgotPassword(): void {
    this.forgotPasswordFormGroup = this.formBuilder.group({
      [this.forgotPasswordFormControlKeys.PhoneNumber]: this.formBuilder.control(this.phoneNumber, [Validators.required]),
    });
  }

  #initGetVerificationOperation(): void {
    this.getVerificationCodeOperation$ = this.requestVerificationCodeService.verificationCode$.pipe(
      tap(({ ok, errorCode, errorMessage }) => (ok ? this.goNext.emit(this.payload) : this.#errorState({ errorCode, errorMessage }))),
      map(() => void 0),
    );

    this.getVerificationCodeLoading$ = this.requestVerificationCodeService.getVerificationCodeLoading$;
  }

  private get payload(): string {
    return this.forgotPasswordFormGroup.get(this.forgotPasswordFormControlKeys.PhoneNumber).value;
  }

  #errorState(state: Pick<models.APIResponse, 'errorCode' | 'errorMessage'>): void {
    this.errorCode = state?.errorCode ?? null;
    this.errorMessage = state?.errorMessage ?? null;

    if (this.errorCode) {
      switch (this.errorCode) {
        case 'email_not_found':
          break;
      }
    }
  }
}
