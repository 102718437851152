<input #file type="file" [id]="id" [disabled]="fileUploadInFly" accept="image/png, image/gif, image/jpg, image/jpeg"
  (change)="uploadFile(file.files)">

<span *ngIf="!showOnlyIcon" class="avatar-container">
  <app-avatar [photoUuid]="uuid" [agentName]="name" [avatarSize]="150"></app-avatar>
  <div class="avatar-actions">
    <ng-container *ngIf="fileUploadInFly; else selectFileLabel">
      <app-ns-spinner></app-ns-spinner>
    </ng-container>
  </div>
</span>

<ng-container *ngIf="showOnlyIcon">
  <label *ngIf="!uuid" class="only-icon-label" [for]="id">
    <img src="/assets/images/white-plus.svg" alt="white-plus">
  </label>

  <button *ngIf="uuid" class="delete" title="Удалить аватар" (click)="deleteFile()">
    <img src="assets/images/delete-icon.svg" alt="delete-icon">
  </button>
</ng-container>

<ng-template #selectFileLabel>
  <label [for]="id">
    Выбрать
  </label>
  <ng-template [ngIf]="uuid">
    <button class="delete" title="Удалить аватар" (click)="deleteFile()">
      <img src="assets/images/delete-icon.svg" alt="delete-icon">
    </button>
  </ng-template>
</ng-template>