import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModalAuthorizationComponent } from '@modules/sign-in/modal-authorization.component';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from '@modules/auth/auth.service';
import { PrincipalService } from '@app/principal/service/principal.service';
import { environment } from '@environments/environment';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private authenticationService: AuthService,
    private router: Router,
    private principalService: PrincipalService,
    private _dialogService: DialogService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          if (err?.error?.error === 'invalid_grant' && err?.error?.error_description === 'Invalid user credentials') {
            return throwError(err);
          }
          if (request.url.includes('/api/users/disableDevice')) {
            this.router.navigate(['/']).then();
            this._dialogService.open(ModalAuthorizationComponent, {
              showHeader: false,
              styleClass: 'modal-authorization-desktop',
            });
            return of(null);
          }
          return this.handle401Error(request, next);
        } else if (err.status === 400) {
          // {
          //   "error": "invalid_grant",
          //   "error_description": "Session not active"
          // }
          if (err.url.includes(environment.authUrl)) {
            if (err.error.error_description.includes('Refresh token expired')) {
              this._dialogService.open(ModalAuthorizationComponent, {
                showHeader: false,
                styleClass: 'modal-authorization-desktop',
              });
              return of(null);
            }
          }
          // show message
        } else if (err.status === 500) {
          // show message
        } else if (err.status === 404) {
          if (err?.error?.error === 'Not Found') {
            return throwError(err);
          }
        }

        let error = err.error.errorMessages;

        if (!err.error.errorMessages) error = ['При выполнении операции возникла ошибка'];
        /*
      if(err.error.errorMessages){
          err.error.errorMessages.forEach(function (message) {
              error += message + '\n';
          });
      } else{
          error = err.statusText;
      }
       */
        return throwError(error);
      }),
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authenticationService.refreshToken().pipe(
        switchMap((token) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.access_token);
          PrincipalService.setToken(token);
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token.access_token}`,
            },
          });
          return next.handle(request);
        }),
        catchError((e) => {
          this.isRefreshing = false;
          this.principalService.logout();
          return throwError(e);
        }),
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${jwt}`,
            },
          });
          return next.handle(request);
        }),
      );
    }
  }

  // handleExpiredRefreshTokenError(request: HttpRequest<any>, next: HttpHandler) {
  //
  // }
}
