<ng-container *ngIf="getVerificationCodeOperation$ | async"></ng-container>

<div class="title">
  {{accountRestore === true ? 'Восстановление аккаунта' : 'Восстановление пароля'}}
</div>

<form [formGroup]="forgotPasswordFormGroup" (ngSubmit)="requestCode()">

  <div class="form-item">
    <label>Телефон</label>
    <div class="input-container login">
      <input
        prefix="+7"
        placeholder="Телефон"
        mask=" (000) 000 00 00"
        type="text"
        [formControlName]="forgotPasswordFormControlKeys.PhoneNumber"
        required
        readonly>
    </div>
  </div>

  <div class="message">

    <ng-container *ngIf="errorCode">
      {{ errorCodeText }}
    </ng-container>

  </div>

  <app-form-actions>

    <button
      pButton
      class="primary"
      type="submit"
      [loading]="getVerificationCodeLoading$ | async"
      [disabled]="forgotPasswordFormGroup.invalid"
      [label]="'Получить код'">
    </button>

    <button
      type="button"
      class="go-back"
      pButton
      label="Назад"
      (click)="goBack()">
    </button>

  </app-form-actions>

</form>
