import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';

import * as models from './setup-password-api.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SetupPasswordApiService {
  constructor(private http: HttpClient) {}

  public setupPassword(token: string, body: models.SetupPasswordBody): Observable<models.SetupPasswordResp> {
    return this.http.put(`${environment.apiUserManagerUrl}/api/users/pass`, body, { headers: new HttpHeaders().set('Authorization', `Bearer ${token}`) });
  }
}
