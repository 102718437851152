import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

import * as authModels from '@modules/sign-in/auth.model';
import { map, tap } from 'rxjs/operators';
import { ValidateVerificationCodeByPhoneNumberService } from './validate-verification-code-by-phone-number.service';

export enum ValidateVerificationCodeByPhoneNumberFormControlKeys {
  PhoneNumber = 'phoneNumber',
  Code = 'code',
}

const VERIFICATION_CODE_LENGTH = 4;

@Component({
  selector: 'app-validate-verification-code-by-phone-number-state',
  templateUrl: './validate-verification-code-by-phone-number-state.component.html',
  styleUrls: ['./validate-verification-code-by-phone-number-state.component.scss'],
  providers: [ValidateVerificationCodeByPhoneNumberService],
})
export class ValidateVerificationCodeByPhoneNumberStateComponent implements OnInit, AfterViewInit {
  @Input()
  public phoneNumber: string;

  @Output()
  public goNext: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public backFromValidateVerification: EventEmitter<void> = new EventEmitter<void>();

  public validateVerificationCodeFormGroup: FormGroup;
  public readonly validateVerificationCodeFormControlKeys = ValidateVerificationCodeByPhoneNumberFormControlKeys;

  public validateVerificationCodeOperation$: Observable<void>;
  public validateVerificationCodeLoading$: Observable<boolean>;

  public errorCode: string;
  public errorMessage: string;

  @ViewChild('code')
  private codeInputElement: ElementRef<HTMLInputElement>;

  constructor(
    private formBuilder: FormBuilder,
    private validateVerificationCodeService: ValidateVerificationCodeByPhoneNumberService,
  ) {}

  ngOnInit(): void {
    this.#initValidateVerificationCodeForm();

    this.#initValidateCodeOperation();
  }

  ngAfterViewInit(): void {
    this.codeInputElement.nativeElement.focus();
  }

  public validateVerificationCode(): void {
    const { phoneNumber: login, code: password } = this.payload;
    this.validateVerificationCodeService.validateVerificationCode({ login, password });
  }

  public goBack(): void {
    this.backFromValidateVerification.emit();
  }

  #initValidateVerificationCodeForm(): void {
    this.validateVerificationCodeFormGroup = this.formBuilder.group({
      [this.validateVerificationCodeFormControlKeys.PhoneNumber]: this.formBuilder.control(this.phoneNumber, [Validators.required]),
      [this.validateVerificationCodeFormControlKeys.Code]: this.formBuilder.control(null, [
        Validators.required,
        Validators.minLength(VERIFICATION_CODE_LENGTH),
        Validators.maxLength(VERIFICATION_CODE_LENGTH),
      ]),
    });
  }

  #initValidateCodeOperation(): void {
    this.validateVerificationCodeOperation$ = this.validateVerificationCodeService.validateVerificationCodeOperation$.pipe(
      tap(({ ok, token, errorCode }) => (ok ? this.goNext.emit(token) : this.#errorState({ errorCode }))),
      map(() => void 0),
    );

    this.validateVerificationCodeLoading$ = this.validateVerificationCodeService.validateVerificationCodeLoading$;
  }

  private get payload(): { phoneNumber: string; code: string } {
    return this.validateVerificationCodeFormGroup.value;
  }

  #errorState(state: Pick<authModels.APIResponse, 'errorCode' | 'errorMessage'> = null) {
    this.errorCode = state?.errorCode ?? null;
    this.errorMessage = state?.errorMessage ?? null;

    if (this.errorCode) {
      switch (this.errorCode) {
        case 'invalid_code':
          this.validateVerificationCodeFormGroup.get(this.validateVerificationCodeFormControlKeys.Code).setValue(null);
          this.validateVerificationCodeFormGroup.updateValueAndValidity();
          this.codeInputElement.nativeElement.focus();
      }
    }
  }

  public get errorCodeText() {
    switch (this.errorCode) {
      case 'invalid_code':
        return 'Неверный код';
      default:
        return 'Не удалось проверить код';
    }
  }
}
