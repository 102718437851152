import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';

import * as models from './accept-agreement-api.model';

@Injectable({
  providedIn: 'root',
})
export class AcceptAgreementApiService {
  constructor(private http: HttpClient) {}

  public accept(token: string, isAccepted: models.AcceptAgreementApiQueryParams['sign']) {
    return this.http.get(`${environment.apiUserManagerUrl}/api/users/signAgreement?sign=${isAccepted}`, { headers: new HttpHeaders().set('Authorization', `Bearer ${token}`) });
  }
}
