import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

enum ForgotPasswordStateList {
  RecoveryMethodSelection = 'recoveryMethodSelection',
  Email = 'email',
  PhoneNumber = 'phoneNumber',
}

@Component({
  selector: 'app-forgot-password-state',
  templateUrl: './forgot-password-state.component.html',
  styleUrls: ['./forgot-password-state.component.scss'],
})
export class ForgotPasswordStateComponent implements OnInit {
  @Input()
  public accountRestore: boolean;

  @Input()
  public phoneNumber: string;

  @Output()
  public goBackFromForgotPass: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public closeForgotPass: EventEmitter<void> = new EventEmitter<void>();

  public forgotPasswordState: ForgotPasswordStateList;
  public readonly forgotPasswordStateList = ForgotPasswordStateList;

  ngOnInit(): void {
    this.forgotPasswordState = this.forgotPasswordStateList.RecoveryMethodSelection;
  }

  public goToSelectRecoveryMethodState(): void {
    this.forgotPasswordState = this.forgotPasswordStateList.RecoveryMethodSelection;
  }

  public close(): void {
    this.closeForgotPass.emit();
  }

  public goBack(): void {
    this.goBackFromForgotPass.emit();
  }
}
