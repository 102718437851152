<input
  #input
  pInputText
  class="p-inputmask form-control"
  [attr.id]="inputId"
  [attr.type]="type"
  [attr.name]="name"
  [ngStyle]="style"
  [ngClass]="styleClass"
  [attr.placeholder]="placeholder?.replace(symbolChanged, numberPrefix)"
  [attr.title]="title"
  [attr.size]="size"
  [attr.autocomplete]="autocomplete"
  [attr.maxlength]="maxlength"
  [attr.tabindex]="tabindex"
  [attr.aria-label]="ariaLabel"
  [attr.aria-labelledBy]="ariaLabelledBy"
  [attr.aria-required]="ariaRequired"
  [disabled]="disabled"
  [readonly]="readonly"
  [attr.required]="required"
  (focus)="onInputFocus($event)"
  (blur)="onInputBlur($event)"
  (keydown)="onInputKeydown($event)"
  (keypress)="onKeyPress($event)"
  [autofocus]="autoFocus"
  (input)="onInputChange($event)"
  (paste)="handleInputChange($event)"
  [attr.data-pc-name]="'inputmask'"
  [attr.data-pc-section]="'root'"
/>
<ng-content></ng-content>