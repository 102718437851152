import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import * as authModels from '../../../../auth.model';

import { AuthService } from '../../../../auth.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SendSmsCodeApiService {
  constructor(private authService: AuthService) {}

  public validateSMSCode({ login, password }: authModels.Credentials): Observable<{ ok: boolean; token?: string; errorCode?: string }> {
    return this.authService.authenticate({ login, password }).pipe(
      map((res) => ({ ok: true, token: res.access_token })),
      catchError((e) => {
        return of({ ok: false, errorCode: e?.error?.error_description || 'unknown_error' });
      }),
    );
  }
}
