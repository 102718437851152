import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { language } from '@environments/language';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  _language = language;

  getFilePath(): string {
    return environment.apiFileManagerUrl + '/open-api/download/';
  }

  getDicNameByLanguage() {
    let fieldName;
    switch (this._language.language) {
      case 'kz':
        fieldName = 'nameKz';
        break;
      case 'en':
        fieldName = 'nameEn';
        break;
      default:
        fieldName = 'nameRu';
        break;
    }
    return fieldName;
  }
}
