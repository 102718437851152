import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export enum SignInState {
  SignIn = 'signIn',
  ForgotPassword = 'forgotPassword',
}

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  public signInState = SignInState.SignIn;
  public readonly signInStateList = SignInState;

  @Input()
  public accountRestore: boolean;

  @Input()
  public phoneNumber: string = null;

  @Output()
  public goBackFromSignIn: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public closeSignUp: EventEmitter<void> = new EventEmitter<void>();

  ngOnInit(): void {
    if (this.accountRestore === true) {
      this.signInState = SignInState.ForgotPassword;
    }
  }

  public close(): void {
    this.closeSignUp.emit();
  }

  public goBack(): void {
    this.goBackFromSignIn.emit();
  }

  public goToSignInState(): void {
    this.signInState = SignInState.SignIn;
  }

  public goToForgotPasswordState(): void {
    this.signInState = SignInState.ForgotPassword;
  }
}
