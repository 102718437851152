<ng-container [ngSwitch]="passwordRecoveryState">

  <ng-container *ngSwitchCase="passwordRecoveryStateList.RequestVerificationCode">
    <app-request-verification-code-by-phone-number-state
      [accountRestore]="accountRestore"
      [phoneNumber]="phoneNumber"
      (goNext)="goToValidateCodeState($event)"
      (goBackFromRequestVerification)="goBack()">
    </app-request-verification-code-by-phone-number-state>
  </ng-container>

  <ng-container *ngSwitchCase="passwordRecoveryStateList.ValidateVerificationCode">
    <app-validate-verification-code-by-phone-number-state
      [phoneNumber]="phoneNumber"
      (goNext)="goToPasswordSetupState($event)"
      (backFromValidateVerification)="goBack()">
    </app-validate-verification-code-by-phone-number-state>
  </ng-container>

  <ng-container *ngSwitchCase="passwordRecoveryStateList.PasswordSetup">
    <app-password-setup-by-phone-number-state
      [accountRestore]="accountRestore"
      [phoneNumber]="phoneNumber"
      [token]="token"
      (closePassSetup)="close()">
    </app-password-setup-by-phone-number-state>
  </ng-container>

</ng-container>
