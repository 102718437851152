import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UtilService } from '@helpers/util.service';

@Component({
  selector: 'app-no-photo',
  templateUrl: './no-photo.component.html',
  styleUrls: ['./no-photo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoPhotoComponent {
  @Input() isMainPhoto: boolean;
  @Input() imageSize: number;
  @Input() objectId: number;
  @Input() rounded = true;

  constructor(public util: UtilService) {}
}
