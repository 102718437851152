import { AddressService } from './address.service';
import { DictionaryService } from './dictionary.service';
import { FileService } from './file.service';
import { GoogleAnalyticsService } from './google-analytics.service';

export const services = [AddressService, DictionaryService, FileService, GoogleAnalyticsService];

export * from './dictionary.service';
export * from './address.service';
export * from './file.service';
export * from './google-analytics.service';
