<ng-select
  #userSelector
  [loading]="loading"
  loadingText="Загрузка..."
  [class]="class"
  [(ngModel)]="value"
  [items]="itemList"
  [bindValue]="bindValue"
  [bindLabel]="bindLabel"
  [placeholder]="placeholder"
  (scrollToEnd)="onNextPage()"
  [notFoundText]="noFoundText"
  [multiple]="multiple"
  (search)="onSearch($event)"
  (open)="onOpen()"
  [searchable]="searchable"
  [clearable]="clearable"
  (change)="onChange($event)"
  [disabled]="disabled">
</ng-select>
