<div class="modal-content">

  <div class="modal-header">
    <span class="title">
      Политика конфиденциальности
    </span>
  </div>

  <div class="modal-body">

    <div class="empty-line"></div>

    <pre>

<!--  TODO: это образец, заменить на текст политики    -->

      ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ №1

г. Тюмень                                                                           26 ноября 2025 года


1. ОБЩИЕ ПОЛОЖЕНИЯ

1.1.	Настоящее Пользовательское соглашение (далее – Соглашение) относится к сайту Интернет-ресурса «Астра», расположенному
по адресу www.astra.ru и ко всем соответствующим сайтам, связанным с сайтом www.astra.ru.
1.2.	Сайт Интернет-ресурса «Астра»  (далее – Сайт) является собственностью ООО «Вид».
1.3.	Настоящее Соглашение регулирует отношения между Администрацией сайта Интернет-ресурса «Астра» (далее – Администрация сайта) и Пользователем данного Сайта.
1.4. Администрация сайта оставляет за собой право в любое время изменять, добавлять или удалять пункты настоящего Соглашения без уведомления Пользователя.
1.5. Продолжение использования Сайта Пользователем означает принятие Соглашения и изменений, внесенных в настоящее Соглашение.
1.6. Пользователь несет персональную ответственность за проверку настоящего Соглашения на наличие изменений в нем.

2.	ОПРЕДЕЛЕНИЯ

2.1.	Перечисленные ниже термины имеют для целей настоящего Соглашения следующее значение:
2.1.1 «Астра» – Интернет-ресурс, расположенный на доменном имени www.astra.ru, осуществляющий свою деятельность посредством Интернет-ресурса и сопутствующих ему сервисов.
2.1.2. Интернет-ресурс – сайт, содержащий информацию о Товарах, Продавце, позволяющий осуществить выбор, заказ и (или) приобретение Товара.
2.1.3. Администрация сайта Интернет-ресурса – уполномоченные сотрудники на управления Сайтом, действующие от имени ООО «Вид».
2.1.4. Пользователь сайта Интернет-ресурса (далее ‑ Пользователь) – лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее Сайт.
2.1.5. Содержание сайта Интернет-ресурса (далее – Содержание) - охраняемые результаты интеллектуальной деятельности, включая тексты литературных произведений, их названия, предисловия, аннотации, статьи, иллюстрации, обложки, музыкальные произведения с текстом или без текста, графические, текстовые, фотографические, производные, составные и иные произведения, пользовательские интерфейсы, визуальные интерфейсы, названия товарных знаков, логотипы, программы для ЭВМ, базы данных, а также дизайн, структура, выбор, координация, внешний вид, общий стиль и расположение данного Содержания, входящего в состав Сайта и другие объекты интеллектуальной собственности все вместе и/или по отдельности, содержащиеся на сайте Интернет-ресурса.

3.	ПРЕДМЕТ СОГЛАШЕНИЯ

3.1. Предметом настоящего Соглашения является предоставление Пользователю Интернет-ресурса доступа к содержащимся на Сайте Товарам и оказываемым услугам.
3.1.1. Интернет-ресурс предоставляет Пользователю следующие виды услуг (сервисов):
•	доступ к электронному контенту на  бесплатной основе, с правом приобретения (скачивания), просмотра контента;
•	доступ к средствам поиска и навигации Интернет-ресурса;
•	предоставление Пользователю возможности размещения сообщений, комментариев, рецензий Пользователей, выставления оценок контенту Интернет-ресурса;
•	доступ к информации о Товаре и к информации о приобретении Товара на  бесплатной основе;
•	иные виды услуг (сервисов), реализуемые на страницах Интернет-ресурса, включая платные услуги (сервисы).
3.1.2. Под действие настоящего Соглашения подпадают все существующие (реально функционирующие) на данный момент услуги (сервисы) Интернет-ресурса, а также любые их последующие модификации и появляющиеся в дальнейшем дополнительные услуги (сервисы) Интернет-ресурса.
3.2. Доступ к Интернет-ресурсу предоставляется на бесплатной основе.
3.3. Настоящее Соглашение является публичной офертой. Получая доступ к Сайту Пользователь считается присоединившимся к настоящему Соглашению.
3.4. Использование материалов и сервисов Сайта регулируется нормами действующего законодательства Российской Федерации

4.	ПРАВА И ОБЯЗАННОСТИ СТОРОН

4.1. Администрация сайта вправе:
4.1.1. Изменять правила пользования Сайтом, а также изменять содержание данного Сайта. Изменения вступают в силу с момента публикации новой редакции Соглашения на Сайте.
4.1.2. Ограничить доступ к Сайту в случае нарушения Пользователем условий настоящего Соглашения.
 4.1.3. Изменять размер оплаты, взимаемый за предоставление доступа к использованию сайта Интернет-ресурса. Изменение стоимости не будет распространяться на Пользователей, имеющих регистрацию к моменту изменения размера оплаты, за исключением случаев, особо оговоренных Администрацией сайта интернет-ресурса.
4.1.4. Собирать, анализировать, использовать, делиться (в том числе на платной основе) информацией о Пользователе, содержащейся на Сайте, включая, но не ограничиваясь, информацией о контактных и личных данных Пользователя, информацией о действиях Пользователя на Сайте и.т.д.

4.2. Пользователь вправе:
4.2.1. Получить доступ к использованию Сайта.
4.2.2. Пользоваться всеми имеющимися на Сайте услугами, а также приобретать любые Товары, предлагаемые на Сайте.
4.2.3. Задавать любые вопросы, относящиеся к услугам Интернет-ресурса по реквизитам, которые находятся в разделе Сайта «Астра».
4.2.4. Пользоваться Сайтом исключительно в целях и порядке, предусмотренных Соглашением и не запрещенных законодательством Российской Федерации.
4.3. Пользователь Сайта обязуется:
4.3.1. Предоставлять по запросу Администрации сайта дополнительную информацию, которая имеет непосредственное отношение к предоставляемым услугам данного Сайта.
4.3.2. Соблюдать имущественные и неимущественные права авторов и иных правообладателей при использовании Сайта.
4.3.3. Не предпринимать действий, которые могут рассматриваться как нарушающие нормальную работу Сайта.
4.3.4. Не распространять с использованием Сайта любую конфиденциальную и охраняемую законодательством Российской Федерации информацию о физических либо юридических лицах.
4.3.5. Избегать любых действий, в результате которых может быть нарушена конфиденциальность охраняемой законодательством Российской Федерации информации.
4.3.6. Не использовать Сайт для распространения информации рекламного характера, иначе как с согласия Администрации сайта.
4.3.7. Не использовать сервисы сайта Интернет-ресурса с целью:
4.3.7. 1. загрузки контента, который является незаконным, нарушает любые права третьих лиц; пропагандирует насилие, жестокость, ненависть и (или) дискриминацию по расовому, национальному, половому, религиозному, социальному признакам; содержит недостоверные сведения и (или) оскорбления в адрес конкретных лиц, организаций, органов власти.
4.3.7. 2. побуждения к совершению противоправных действий, а также содействия лицам, действия которых направлены на нарушение ограничений и запретов, действующих на территории Российской Федерации.
4.3.7. 3. нарушения прав несовершеннолетних лиц и (или) причинение им вреда в любой форме.
4.3.7. 4. ущемления прав меньшинств.
4.3.7. 5. представления себя за другого человека или представителя организации и (или) сообщества без достаточных на то прав, в том числе за сотрудников данного Интернет-ресурса.
4.3.7. 6. введения в заблуждение относительно свойств и характеристик какого-либо Товара из каталога Интернет-ресурса, размещенного на Сайте.
4.3.7. 7. некорректного сравнения Товара, а также формирования негативного отношения к лицам, (не) пользующимся определенными Товарами, или осуждения таких лиц.
4.4. Пользователю запрещается:
4.4.1. Использовать любые устройства, программы, процедуры, алгоритмы и методы, автоматические устройства или эквивалентные ручные процессы для доступа, приобретения, копирования или отслеживания содержания Сайта данного Интернет-ресурса;
4.4.2. Нарушать надлежащее функционирование Сайта;
4.4.3. Любым способом обходить навигационную структуру Сайта для получения или попытки получения любой информации, документов или материалов любыми средствами, которые специально не представлены сервисами данного Сайта;
4.4.4. Несанкционированный доступ к функциям Сайта, любым другим системам или сетям, относящимся к данному Сайту, а также к любым услугам, предлагаемым на Сайте;
4.4.4. Нарушать систему безопасности или аутентификации на Сайте или в любой сети, относящейся к Сайту.
4.4.5. Выполнять обратный поиск, отслеживать или пытаться отслеживать любую информацию о любом другом Пользователе Сайта.
4.4.6. Использовать Сайт и его Содержание в любых целях, запрещенных законодательством Российской Федерации, а также подстрекать к любой незаконной деятельности или другой деятельности, нарушающей права интернет-ресурса или других лиц.

5.	ИСПОЛЬЗОВАНИЕ САЙТА

5.1. Сайт и Содержание, входящее в состав Сайта, принадлежит и управляется Администрацией сайта.
5.2. Содержание Сайта не может быть скопировано, опубликовано, воспроизведено, передано или распространено любым способом, а также размещено в глобальной сети «Интернет» без предварительного письменного согласия Администрации сайта.
5.3. Содержание Сайта защищено авторским правом, законодательством о товарных знаках, а также другими правами, связанными с интеллектуальной собственностью, и законодательством о недобросовестной конкуренции.
5.4. При использовании некоторых сервисов сайта, может потребоваться создание учётной записи Пользователя.
5.5. Пользователь несет персональную ответственность за сохранение конфиденциальности информации учётной записи, включая пароль, а также за всю без исключения деятельность, которая ведётся от имени Пользователя учётной записи.
5.6. Пользователь должен незамедлительно уведомить Администрацию сайта о несанкционированном использовании его учётной записи или пароля или любом другом нарушении системы безопасности.
5.7. Администрация сайта обладает правом в одностороннем порядке аннулировать учетную запись Пользователя, если она не использовалась более 3 (трех) календарных месяцев подряд без уведомления Пользователя.
5.7. Настоящее Соглашение распространяет свое действия на все дополнительные положения и условия о покупке Товара и оказании услуг, предоставляемых на Сайте.
5.8. Информация, размещаемая на Сайте не должна истолковываться как изменение настоящего Соглашения.
5.9. Администрация сайта имеет право в любое время без уведомления Пользователя вносить изменения в перечень Товаров и услуг, предлагаемых на Сайте, и (или) в цены, применимые к таким Товарам по их реализации и (или) оказываемым услугам Интернет-ресурсом.
5.10. Документы, указанные в пунктах 5.10.1 - 5.10.4 настоящего Соглашения регулируют в соответствующей части и распространяют свое действие на использование Пользователем Сайта. В настоящее Соглашение включены следующие документы:
5.10.1. Политика конфиденциальности;
5.10.2. Договор купли-продажи товаров дистанционным способом;
5.10.3. Заявка на оформление заказа;
5.10.4. Предложения и замечания.
5.11. Любой из документов, перечисленных в пункте 5.10. настоящего Соглашения может подлежать обновлению. Изменения вступают в силу с момента их опубликования на Сайте.

6.	ОТВЕТСТВЕННОСТЬ

6.1. Любые убытки, которые Пользователь может понести в случае умышленного или неосторожного нарушения любого положения настоящего Соглашения, а также вследствие несанкционированного доступа к коммуникациям другого Пользователя, Администрацией сайта не возмещаются.
6.2. Администрация сайта не несет ответственности за:
6.2.1. Задержки или сбои в процессе совершения операции, возникшие вследствие непреодолимой силы, а также любого случая неполадок в телекоммуникационных, компьютерных, электрических и иных смежных системах.
6.2.2. Действия систем переводов, банков, платежных систем и за задержки связанные с их работой.
6.2.3. Надлежащее функционирование Сайта, в случае, если Пользователь не имеет необходимых технических средств для его использования, а также не несет никаких обязательств по обеспечению пользователей такими средствами.
6.2.4. Содержание (контент) информации размещенной на Сайте.
6.3. Пользователь самостоятельно несет ответственность за интерпретацию и использование контента (информации), размещенного на Сайте.

7.	НАРУШЕНИЕ УСЛОВИЙ ПОЛЬЗОВАТЕЛЬСКОГО СОГЛАШЕНИЯ

7.1. Администрация сайта вправе раскрыть любую собранную о Пользователе данного Сайта информацию, если раскрытие необходимо в связи с расследованием или жалобой в отношении неправомерного использования Сайта либо для установления (идентификации) Пользователя, который может нарушать или вмешиваться в права Администрации сайта или в права других Пользователей Сайта.
7.2. Администрация сайта имеет право раскрыть любую информацию о Пользователе, которую посчитает необходимой для выполнения положений действующего законодательства или судебных решений, обеспечения выполнения условий настоящего Соглашения, защиты прав или безопасности название организации, Пользователей.
7.3. Администрация сайта имеет право раскрыть информацию о Пользователе, если действующее законодательство Российской Федерации требует или разрешает такое раскрытие.
7.4. Администрация сайта вправе без предварительного уведомления Пользователя прекратить и (или) заблокировать доступ к Сайту, если Пользователь нарушил настоящее Соглашение или содержащиеся в иных документах условия пользования Сайтом, а также в случае прекращения действия Сайта либо по причине технической неполадки или проблемы.
7.5. Администрация сайта не несет ответственности перед Пользователем или третьими лицами за прекращение доступа к Сайту в случае нарушения Пользователем любого положения настоящего Соглашения или иного документа, содержащего условия пользования Сайтом.

8.	РАЗРЕШЕНИЕ СПОРОВ

8.1. В случае возникновения любых разногласий или споров между Сторонами настоящего Соглашения обязательным условием до обращения в суд является предъявление претензии (письменного предложения о добровольном урегулировании спора).
8.2. Получатель претензии в течение 30 (тридцати) календарных дней со дня ее получения, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.
8.3. При невозможности разрешить спор в добровольном порядке любая из Сторон вправе обратиться в суд за защитой своих прав, которые предоставлены им действующим законодательством Российской Федерации.
8.4. Любой иск в отношении условий использования Сайта должен быть предъявлен в течение срока после возникновения оснований для иска, за исключением защиты авторских прав на охраняемые в соответствии с законодательством материалы Сайта. При нарушении условий данного пункта любой иск или основания для иска погашаются исковой давностью.

9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ

9.1. Администрация сайта не принимает встречные предложения от Пользователя относительно изменений настоящего Пользовательского соглашения.
9.2. Отзывы Пользователя, размещенные на Сайте, не являются конфиденциальной информацией и могут быть использованы Администрацией сайта без ограничений.

    </pre>

  </div>

</div>
