import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { SettingsService } from '@app/settings.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { DictionaryList } from '@shared/models';
import { DictionaryService } from '@shared/services';
import { Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

type DictionaryValue = { [value: string]: number | string; label: string };

@Component({
  selector: 'app-dictionary-select',
  templateUrl: './dictionary-select.component.html',
  styleUrls: ['./dictionary-select.component.scss'],
})
export class DictionarySelectComponent implements OnInit, OnDestroy {
  @ViewChild('dictionaryValueSelector', { static: true }) private dictionaryValueSelector: NgSelectComponent;

  public loading = false;

  @Input()
  public readonly id: string = null;

  @Input()
  public readonly initialLoad: boolean = false;

  @Input()
  public readonly bindValue: string = 'value';

  @Input()
  public selectedValue: number | string = null;

  @Input()
  public selectedItem: DictionaryValue = null;

  @Input()
  public readonly multiple: boolean = false;

  @Input()
  public readonly className: string = null;

  @Input()
  public readonly placeholder: string = null;

  @Input()
  public readonly dictionaryName: DictionaryList = null;

  @Input()
  public readonly title: string = null;

  public dictionaryValueList: DictionaryValue[] = [];

  #dictionaryList: Subscription;

  @Output()
  public selected = new EventEmitter();

  constructor(
    private settingsService: SettingsService,
    private dictionaryService: DictionaryService,
  ) {}

  ngOnInit(): void {
    if (this.initialLoad) {
      this.initialDataLoad();
    }
  }

  public initialDataLoad(): void {
    this.loading = true;
    switch (this.dictionaryName) {
      default:
        this.loading = false;
    }
  }

  public onOpen(): void {
    this.loading = true;
    switch (this.dictionaryName) {
      case DictionaryList.OperationType:
        this.#dictionaryList = this.dictionaryService
          .fetchDictValues(DictionaryList.OperationType)
          .pipe(
            map((list) => list.map((item) => ({ [this.bindValue]: item.id, label: item.value }))),
            tap((list) => this.#setData(list)),
            tap(() => (this.loading = false)),
          )
          .subscribe();
        break;
      case DictionaryList.ObjectType:
        this.#dictionaryList = this.dictionaryService
          .fetchDictValues(DictionaryList.ObjectType)
          .pipe(
            map((list) => list.map((item) => ({ [this.bindValue]: item.id, label: item.value }))),
            tap((list) => this.#setData(list)),
            tap(() => (this.loading = false)),
          )
          .subscribe();
        break;
      default:
        this.loading = false;
    }
  }

  #setData(list: DictionaryValue[]): void {
    this.dictionaryValueList = list;
  }

  public onChange(data: any): void {
    Object.prototype.toString.call(data) === '[object Array]'
      ? this.selected.emit((data as DictionaryValue[]).map((item) => item[this.bindValue]))
      : this.selected.emit((data as DictionaryValue) ? data[this.bindValue] : null);
  }

  public clear(): void {
    this.dictionaryValueSelector.clearModel();
  }

  ngOnDestroy(): void {
    if (this.#dictionaryList?.closed === false) {
      this.#dictionaryList.unsubscribe();
    }
  }
}
