import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { PhoneStatusApiService } from '@modules/sign-in/components/initial/phone-status-api.service';

import * as models from './phone-status.model';

@Injectable({
  providedIn: 'root',
})
export class PhoneIsRegisteredService {
  #getIsPhoneRegisteredLoadingSubject = new Subject<boolean>();
  public getIsPhoneRegisteredLoading$ = this.#getIsPhoneRegisteredLoadingSubject.asObservable();

  #isPhoneRegisteredSubject = new Subject<string>();
  public isPhoneRegistered$ = this.#isPhoneRegisteredSubject.asObservable().pipe(
    tap(() => this.#getIsPhoneRegisteredLoadingSubject.next(true)),
    exhaustMap((value) => this.#fetchIsPhoneRegisteredStatus(value)),
    tap(() => this.#getIsPhoneRegisteredLoadingSubject.next(false)),
  );

  constructor(private phoneStatus: PhoneStatusApiService) {}

  public getPhoneNumberStatus(value: string): void {
    this.#isPhoneRegisteredSubject.next(value);
  }

  #fetchIsPhoneRegisteredStatus(value: string): Observable<models.LoginCheckStatus> {
    return this.phoneStatus.fetchPhoneStatus(value).pipe(
      map((resp) => {
        let res;

        if (resp.body.isSignedAgreement === false) {
          res = models.LoginCheckStatus.Resume;
        } else if (resp.body.hasIdpId === true && resp.body.isActive === true) {
          res = models.LoginCheckStatus.Authorize;
        } else if (resp.body.hasIdpId === false && resp.body.isActive === false) {
          res = models.LoginCheckStatus.Register;
        } else {
          res = models.LoginCheckStatus.Restore;
        }

        return res;
      }),
      catchError((e) => {
        if (e.status === 404) {
          return of(models.LoginCheckStatus.Register);
        }
        return of(e);
      }),
    );
  }
}
