import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as models from '@modules/profile/profile.model';
import { HttpClient } from '@angular/common/http';
import { USER_MANAGER_URI } from 'const';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(
    @Inject(USER_MANAGER_URI) private readonly userManagerURI: string,
    private http: HttpClient,
  ) {}

  public getUserProfile(): Observable<models.UserProfile> {
    return this.http.get<models.UserProfile>(`${this.userManagerURI}/api/profile`);
  }
}
