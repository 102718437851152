import { Component, Input, OnInit } from '@angular/core';
import { interval, Observable, Subject } from 'rxjs';
import { finalize, map, switchMap, take, tap } from 'rxjs/operators';
import * as models from '@modules/sign-in/components/sign-up/components/send-code/send-code.model';
import { RequestCodeService } from '@modules/sign-in/components/sign-up/components/send-code/request-code.service';

@Component({
  selector: 'app-resend-sms-code',
  templateUrl: './resend-sms-code.component.html',
  styleUrls: ['./resend-sms-code.component.scss'],
})
export class ResendSmsCodeComponent implements OnInit {
  @Input()
  public phoneNumber: string;

  public errorCode: string;

  public codeResendInFly = false;

  public resendCodeAfterSubject = new Subject<void>();
  public resendCodeAfter$: Observable<number> = this.resendCodeAfterSubject.asObservable().pipe(switchMap(() => this.#resendAfterTimer()));

  public requestCodeOperation$: Observable<any>;

  #resendCodeRequestSubject = new Subject<models.SendCodePayload>();
  public resendCodeRequest$: Observable<models.SendCodePayload> = this.#resendCodeRequestSubject.asObservable().pipe(
    tap(() => (this.codeResendInFly = true)),
    tap(() => this.requestCodeService.requestCode(this.phoneNumber)),
    tap(() => this.resendCodeAfterSubject.next()),
  );

  constructor(private requestCodeService: RequestCodeService) {}

  ngOnInit(): void {
    this.requestCodeOperation$ = this.requestCodeService.requestCode$;
  }

  public requestCode(): void {
    this.#resendCodeRequestSubject.next(null);
  }

  #resendAfterTimer() {
    return interval(1000).pipe(
      take(120),
      map((v) => 120 - (v + 1)),
      finalize(() => (this.codeResendInFly = false)),
    );
  }
}
