import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { STREAMING_SERVICE_URI } from '@modules/video-player/video-player.module';
import { FILE_MANAGER_URI_TOKEN } from 'const';

enum ImageType {
  Image = 'image',
  ImageThumbnail = 'thumbnail',
}

@Component({
  selector: 'app-real-property-media-content',
  templateUrl: './real-property-media-content.component.html',
  styleUrls: ['./real-property-media-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RealPropertyMediaContentComponent implements OnInit {
  public imageNotFound = false;

  public imageThumbnailNotFound = false;

  @Input()
  public imageIdList: Array<string> = [];

  @Input()
  public videoIdList: Array<string> = [];

  constructor(
    @Inject(FILE_MANAGER_URI_TOKEN) private fileManagerURI: string,
    @Inject(STREAMING_SERVICE_URI) private streamingServiceUrl: string,
  ) {}

  ngOnInit(): void {
    this.imageIdList = this._validateInputs(this.imageIdList);

    this.videoIdList = this._validateInputs(this.videoIdList);
  }

  public get imagePath(): string {
    return `${this.fileManagerURI}/open-api/download/${this.imageIdList[0]}`;
  }

  public get hasImage(): boolean {
    return this.imageIdList.length > 0;
  }

  public get hasVideo(): boolean {
    return this.videoIdList.length > 0;
  }

  public get videoThumbnail(): string {
    return `${this.streamingServiceUrl}${this.videoIdList[0].replace('/master.m3u8', '')}/thumbnail.png`;
  }

  public onImageDownloadError(type: ImageType): void {
    switch (type) {
      case ImageType.Image:
        this.imageNotFound = true;
        break;
      case ImageType.ImageThumbnail:
        this.imageThumbnailNotFound = true;
        break;
    }
  }

  _validateInputs(prop: string[]): Array<string> {
    if (prop === undefined || prop === null) {
      prop = [];
    }

    return prop;
  }
}
