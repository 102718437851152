import { Injectable } from '@angular/core';
import { SetupPasswordApiService } from '@modules/sign-in/components/sign-in/components/forgot-password-state/components/password-recovery-by-phone-number-state/setup-password-api.service';
import { Subject } from 'rxjs';
import { AuthService } from '@modules/auth/auth.service';
import { exhaustMap, switchMap, tap } from 'rxjs/operators';

import * as models from './setup-password-api.model';
import * as authModels from '../../../../../../auth.model';

@Injectable({
  providedIn: 'root',
})
export class SetupPasswordService {
  constructor(
    private passwordSetupService: SetupPasswordApiService,
    private authService: AuthService,
  ) {}

  #setupPasswordOperationInFlySubject: Subject<boolean> = new Subject<boolean>();
  public setupPasswordOperationInFly$ = this.#setupPasswordOperationInFlySubject.asObservable();

  #setupPasswordSubject: Subject<{ token: string; credentials: authModels.Credentials }> = new Subject<{ token: string; credentials: authModels.Credentials }>();
  public setupPassword$ = this.#setupPasswordSubject.asObservable().pipe(
    tap(() => this.#setupPasswordOperationInFlySubject.next(true)),
    exhaustMap(({ token, credentials }) => this.#setupPassword(token, { pass: credentials.password }).pipe(switchMap(() => this.authService.login(credentials)))),
    tap(() => this.#setupPasswordOperationInFlySubject.next(false)),
  );

  public setupPassword({ token, credentials }: { token: string; credentials: authModels.Credentials }): void {
    this.#setupPasswordSubject.next({ token, credentials });
  }

  #setupPassword(token: string, payload: models.SetupPasswordBody) {
    return this.passwordSetupService.setupPassword(token, payload);
  }
}
