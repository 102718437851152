import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { SignInService } from './sign-in.service';

import * as authModels from '../../../../auth.model';

enum SignInFormKeys {
  Login = 'login',
  Password = 'password',
}

@Component({
  selector: 'app-sign-in-state',
  templateUrl: './sign-in-state.component.html',
  styleUrls: ['./sign-in-state.component.scss'],
  providers: [SignInService],
})
export class SignInStateComponent implements OnInit, AfterViewInit {
  @Input()
  public phoneNumber: string;

  public signInFormGroup: FormGroup;
  public signInFormKeys = SignInFormKeys;

  public signInOperation$: Observable<void>;
  public signInOperationInFly$: Observable<boolean>;

  @Output()
  public goNext: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public goBackFromSignInState: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public forgotPasswordState: EventEmitter<string> = new EventEmitter<string>();

  public errorCode: string;

  @ViewChild('password')
  private readonly passwordInputElement: ElementRef<HTMLInputElement>;

  constructor(
    private formBuilder: FormBuilder,
    private signInService: SignInService,
  ) {}

  ngOnInit(): void {
    this.#initSignInForm();

    this.#initSignInOperation();
  }

  ngAfterViewInit(): void {
    this.passwordInputElement.nativeElement.focus();
  }

  public login(): void {
    this.#errorState();

    this.signInService.signIn(this.credentials);
  }

  public goToForgotPasswordState(): void {
    this.forgotPasswordState.emit(this.phoneNumber);
  }

  public goBack(): void {
    this.goBackFromSignInState.emit();
  }

  public get errorCodeText(): string {
    switch (this.errorCode) {
      case 'phone_number_not_found':
        return 'Неверный логин или пароль';
      default:
        return 'Неверный логин или пароль';
    }
  }

  #initSignInForm(): void {
    this.signInFormGroup = this.formBuilder.group({
      [SignInFormKeys.Login]: this.formBuilder.control(this.phoneNumber, [Validators.required]),
      [SignInFormKeys.Password]: this.formBuilder.control(null, [Validators.required]),
    });
  }

  #initSignInOperation(): void {
    this.signInOperation$ = this.signInService.signIn$.pipe(
      tap((res) => {
        typeof res === 'string' ? this.#errorState({ errorCode: res }) : this.goNext.emit();
      }),
      catchError((errorDescription) => {
        switch (errorDescription) {
          case 'Invalid user credentials':
            this.#errorState({ errorCode: 'Неверный логин или пароль' });
            break;

          default:
            this.#errorState({ errorCode: 'Произошла ошибка' });
            break;
        }

        return of(null);
      }),
      map(() => void 0),
    );

    this.signInOperationInFly$ = this.signInService.signInOperationInFly$;
  }

  #errorState(state: Pick<authModels.APIResponse, 'errorCode'> = null) {
    this.errorCode = state?.errorCode;
  }

  private get credentials(): authModels.Credentials {
    return this.signInFormGroup.value;
  }
}
