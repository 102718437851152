import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import * as models from './send-code.model';

import { SmsCodeService } from './sms-code.service';

import * as authModels from '../../../../auth.model';

enum ValidateSMSCodeFormControlKeys {
  PhoneNumber = 'phoneNumber',
  Code = 'code',
}

const SMS_CODE_LENGTH = 4;

@Component({
  selector: 'app-send-code',
  templateUrl: './send-code.component.html',
  styleUrls: ['./send-code.component.scss'],
})
export class SendCodeComponent implements OnInit, AfterViewInit {
  @Input()
  public phoneNumber: string;

  public validateSMSCodeFormGroup: FormGroup;
  public readonly validateSMSCodeFormControlKeys = ValidateSMSCodeFormControlKeys;

  public codeInputFormControl$: Observable<void>;

  public errorCode: string;

  @Output()
  public goBackFromSendCode: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public goToRegisterUserState: EventEmitter<string> = new EventEmitter<string>();

  public validateSMSCodeOperation$: Observable<void>;
  public validateSMSCodeOperationInFly$: Observable<boolean>;

  @ViewChild('code')
  private codeInputElement: ElementRef<HTMLInputElement>;

  constructor(
    private formBuilder: FormBuilder,
    private smsCodeService: SmsCodeService,
  ) {}

  ngOnInit(): void {
    this.#initSendCodeForm();
    this.#initValidateSMSCodeOperation();
  }

  ngAfterViewInit(): void {
    this.codeInputElement.nativeElement.focus();
  }

  public validateSMSCode(): void {
    this.#setErrorState();
    this.smsCodeService.sendCode(this.sendCodePayload);
  }

  public goBack(): void {
    this.goBackFromSendCode.emit();
  }

  public get errorCodeText(): string {
    switch (this.errorCode) {
      case 'Invalid user credentials':
        return 'Неверный код';
      default:
        return 'Не удалось проверить код';
    }
  }

  #initSendCodeForm(): void {
    this.validateSMSCodeFormGroup = this.formBuilder.group({
      [this.validateSMSCodeFormControlKeys.PhoneNumber]: this.formBuilder.control(this.phoneNumber || '7776750007', [Validators.required]),
      [this.validateSMSCodeFormControlKeys.Code]: this.formBuilder.control(null, [Validators.required, Validators.maxLength(SMS_CODE_LENGTH), Validators.minLength(SMS_CODE_LENGTH)]),
    });

    this.codeInputFormControl$ = this.validateSMSCodeFormGroup.get(this.validateSMSCodeFormControlKeys.Code).valueChanges.pipe(
      filter((value) => value !== null),
      tap(() => this.#setErrorState()),
      map(() => void 0),
    );
  }

  #initValidateSMSCodeOperation(): void {
    this.validateSMSCodeOperation$ = this.smsCodeService.sendCodeOperation$.pipe(
      tap(({ ok, token, errorCode }) => (ok ? this.goToRegisterUserState.emit(token) : this.#setErrorState({ errorCode }))),
      map(() => void 0),
    );

    this.validateSMSCodeOperationInFly$ = this.smsCodeService.sendCodeOperationInFly$;
  }

  private get sendCodePayload(): models.SendCodePayload {
    return this.validateSMSCodeFormGroup.value;
  }

  #setErrorState(state: Pick<authModels.APIResponse, 'errorCode'> = null): void {
    this.errorCode = state?.errorCode ?? null;

    if (this.errorCode) {
      switch (this.errorCode) {
        case 'Invalid user credentials':
          this.validateSMSCodeFormGroup.get(this.validateSMSCodeFormControlKeys.Code).setValue(null);
          this.codeInputElement.nativeElement.focus();
          break;
      }
    }
  }
}
