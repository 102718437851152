<div class="desktop-view" *ngIf="!isMobile">
<div class="avatar" [ngStyle]="{ width: avatarSize + 'px', height: avatarSize + 'px' }">
  <img *ngIf="photoUuid" [src]="url" alt="avatar" (error)="onError($event)" loading="lazy">
  <div class="abbr" *ngIf="!photoUuid">
    <span>{{agentName?.charAt(0).toUpperCase()}}</span>
  </div>
</div>
</div>

<div class="mobile-view" *ngIf="isMobile">
  <div class="avatar" [class.full]="size === 'full'" [class.smaller]="sizeAvatar === 'small'">
    <img *ngIf="photoUuid" [src]="url" alt="avatar" (error)="onError($event)" loading="lazy">
    <div class="abbr" *ngIf="!photoUuid">
      <span>{{agentName?.charAt(0).toUpperCase()}}</span>
    </div>
    <div *ngIf="label" class="label">{{ label }}</div>
  </div>
</div>
