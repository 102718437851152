<div class="p-d-flex modal-content content">

  <div class="content-container">

    <div class="desktop-view modal-header p-d-flex p-jc-end">
      <button class="close"><i class="pi pi-times" (click)="onClose()"></i></button>
    </div>

    <div class="mobile-view">
      <img class="closer" src="assets/images/arrow-left.svg" (click)="onClose()">
    </div>

    <div class="card">

      <div class="modal-body">

        <ng-container [ngSwitch]="authState">

          <ng-container *ngSwitchCase="authStateList.Initial">

            <app-initial [phoneNumber]="phoneNumber" (phoneNumberChange)="onPhoneNumberChange($event)"
              (signInStateChange)="onSignIn()" (signUpStateChange)="onSignUp($event)"
              (accountRestoreStateChange)="onAccountRestore()">
            </app-initial>

          </ng-container>

          <ng-container *ngSwitchCase="authStateList.SignIn">

            <app-sign-in [accountRestore]="accountRestore" [phoneNumber]="phoneNumber" (closeSignUp)="onClose()"
              (goBackFromSignIn)="goBack()">
            </app-sign-in>

          </ng-container>

          <ng-container *ngSwitchCase="authStateList.SignUp">

            <app-sign-up [resumeRegister]="resumeRegister" [phoneNumber]="phoneNumber" (closeSignUp)="onClose()"
              (goBackFromSignUp)="goBack()">
            </app-sign-up>

          </ng-container>

        </ng-container>

      </div>

    </div>

    <div class="card p-d-flex p-flex-column p-ai-center" (click)="openApp()">

      Ссылки на приложение

    </div>

  </div>

</div>