<ng-container [ngSwitch]="signUpState">

  <ng-container *ngSwitchCase="signUpStateList.SendCode">
    <app-send-code
      [phoneNumber]="phoneNumber"
      (goToRegisterUserState)="goToRegisterUserState($event)"
      (goBackFromSendCode)="goBack()">
    </app-send-code>
  </ng-container>

  <ng-container *ngSwitchCase="signUpStateList.RegisterUser">
    <app-register-user-state
      [phoneNumber]="phoneNumber"
      [token]="token"
      (goNext)="onSignUp()">
    </app-register-user-state>
  </ng-container>

</ng-container>
