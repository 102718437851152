import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { SettingsService } from '@app/settings.service';

@Component({
  selector: 'app-real-property-agent-link',
  templateUrl: './real-property-agent-link.component.html',
  styleUrls: ['./real-property-agent-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RealPropertyAgentLinkComponent implements OnInit {
  @Input()
  public readonly id: number | string = null;

  @Input()
  public queryParams: Params = null;

  constructor(private _settingsService: SettingsService) {}

  ngOnInit(): void {
    if (this._settingsService.numberAgentSession) {
      this.queryParams = {
        ...this.queryParams,
        info: this._settingsService.numberAgentSession,
      };
    }
  }
}
