import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as models from './account-restore.model';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountRestoreService {
  constructor(private http: HttpClient) {}

  public restore(): Observable<models.AccountRestoreResp> {
    return this.http.get<models.AccountRestoreResp>(`${environment.apiUserManagerUrl}/api/users/restore`);
  }
}
