import { Component, OnInit } from '@angular/core';
import { SettingsService } from '@app/settings.service';
import { SellRealPropertyApplicationFormComponent } from '@modules/real-property-lead/sell-real-property-application-form.component';
import { DialogService } from 'primeng/dynamicdialog';
@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss'],
})
export class MobileHeaderComponent implements OnInit {
  socialLinks = [
    {
      icon: 'assets/images/instagram-color.svg',
      title: 'Instagram',
      alt: 'instagram',
      link: 'https://www.instagram.com/ivitrina.kz/?igshid=YmMyMTA2M2Y=',
    },
    {
      icon: 'assets/images/facebook-color.svg',
      title: 'Facebook',
      alt: 'facebook',
      link: 'https://www.facebook.com/vitrina.nedvizhimosti/',
    },
    {
      icon: 'assets/images/youtube-color.svg',
      title: 'YouTube',
      alt: 'youtube',
      link: 'https://youtube.com',
    },
  ];
  isShowNumber = false;

  constructor(
    private dialogService: DialogService,
    public settingsService: SettingsService,
  ) {}

  ngOnInit(): void {
    this.settingsService.changePhoneNumber$.subscribe((data) => {
      this.isShowNumber = data;
    });
  }

  public openRealPropertyLead(): void {
    this.dialogService.open(SellRealPropertyApplicationFormComponent, {
      showHeader: false,
      styleClass: 'mobile-sell-real',
      contentStyle: {
        overflow: 'auto',
        'border-radius': '20px',
      },
    });
  }

  get phoneNumber() {
    return this.settingsService.getPhoneCallNumber();
  }

  isShowContact() {
    return this.settingsService.isHideCallCenterNumber;
  }
}
