<ng-container *ngIf="validateVerificationCodeOperation$ | async"></ng-container>

<div class="title">

  Укажите верификационный код, отправленный на указанный номер телефона.

</div>

<form [formGroup]="validateVerificationCodeFormGroup" (ngSubmit)="validateVerificationCode()">

  <div class="form-item">
    <label>Телефон</label>
    <div class="input-container login">
      <input
        prefix="+7"
        mask=" (000) 000 00 00"
        type="text"
        [formControlName]="validateVerificationCodeFormControlKeys.PhoneNumber"
        readonly>
    </div>
  </div>

  <div class="form-item">
    <label>Код</label>
    <div class="input-container">
      <input
        #code
        mask="0000"
        type="text"
        placeholder="0000"
        [formControlName]="validateVerificationCodeFormControlKeys.Code"
        autocomplete="new-password"
        minlength="4"
        maxlength="4"
        required>
    </div>
  </div>

  <div class="code-not-received">
    <app-resend-sms-code
      [phoneNumber]="phoneNumber">
    </app-resend-sms-code>
  </div>

  <div class="message">

    <ng-container *ngIf="errorCode">
      {{ errorCodeText }}
    </ng-container>

  </div>

  <app-form-actions>

    <button
      pButton
      class="primary"
      type="submit"
      [loading]="validateVerificationCodeLoading$ | async"
      [disabled]="validateVerificationCodeFormGroup.invalid"
      [label]="'Отправить код'">
    </button>

    <button
      type="button"
      class="go-back"
      pButton
      label="Назад"
      (click)="goBack()">
    </button>

  </app-form-actions>

</form>
